/**
 * Helpers
 */
import { createActionCreator } from '../../helpers';

const actionTypes = {
  APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP: 'APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP',
  APPROVING_PAYMENT_OUT_RECORD_DETAIL_GROUP: 'APPROVING_PAYMENT_OUT_RECORD_DETAIL_GROUP',
  DECREMENT_APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW: 'DECREMENT_APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW',
  DECREMENT_REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW: 'DECREMENT_REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW',
  INCREMENT_APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW: 'INCREMENT_APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW',
  INCREMENT_REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW: 'INCREMENT_REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW',
  REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP: 'REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP',
  REJECTING_PAYMENT_OUT_RECORD_DETAIL_GROUP: 'REJECTING_PAYMENT_OUT_RECORD_DETAIL_GROUP',
};
const actionCreators = {
  approvePaymentOutRecordDetailGroup: createActionCreator(actionTypes.APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP),
  approvingPaymentOutRecordDetailGroup: createActionCreator(actionTypes.APPROVING_PAYMENT_OUT_RECORD_DETAIL_GROUP),
  decrementApprovePaymentOutRecordDetailGroupDraw: createActionCreator(actionTypes.DECREMENT_APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW),
  decrementRejectPaymentOutRecordDetailGroupDraw: createActionCreator(actionTypes.DECREMENT_REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW),
  incrementApprovePaymentOutRecordDetailGroupDraw: createActionCreator(actionTypes.INCREMENT_APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW),
  incrementRejectPaymentOutRecordDetailGroupDraw: createActionCreator(actionTypes.INCREMENT_REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP_DRAW),
  rejectingPaymentOutRecordDetailGroup: createActionCreator(actionTypes.REJECTING_PAYMENT_OUT_RECORD_DETAIL_GROUP),
  rejectPaymentOutRecordDetailGroup: createActionCreator(actionTypes.REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP),
};
export {
  actionCreators,
  actionTypes,
};
