const getPaymentOutRecordsScopes = [
  'PAYMENT_OUT_RECORDS_REMOVED',
  'PAYMENT_OUT_RECORDS_UNRESOLVED',
];
const chains = [];
chains.push({ _operation: 'createPaymentOutRecord', api: '/payment-out-record', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getPaymentOutRecords', api: '/payment-out-records', defaultData: [], httpVerb: 'get', scopes: getPaymentOutRecordsScopes });
chains.push({ _operation: 'removePaymentOutRecordById', api: '/payment-out-record/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updatePaymentOutRecordById', api: '/payment-out-record/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
