/**
 * Node modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';

/**
 * Redux actions
 */
import {
  actionCreators as userAuthenticationsActionCreators,
  actionTypes as userAuthenticationActionTypes,
} from './actions';

/**
 * Services
 */
import serviceMap from '../../../../services';

const requestAuthenticate = function* ({ payload }) {
  try {
    yield put(userAuthenticationsActionCreators.authenticating({ loading: true }));
    const { response: authenticateResponse } = yield call(serviceMap['request-authenticate'], payload);
    const { token } = authenticateResponse.data;
    yield put(userAuthenticationsActionCreators.authenticated({ token }));
    yield put(userAuthenticationsActionCreators.incrementAuthenticateDraw({ response: authenticateResponse.message }));
    yield put(userAuthenticationsActionCreators.authenticating({ loading: false }));
  } catch (error) {
    yield put(userAuthenticationsActionCreators.decrementAuthenticateDraw({ response: error.message }));
    yield put(userAuthenticationsActionCreators.authenticating({ loading: false }));
  }
};
const requestAuthorize = function* ({ payload }) {
  try {
    yield put(userAuthenticationsActionCreators.authorizing({ loading: true }));
    const { response: authorizeResponse } = yield call(serviceMap['request-authorize'], payload);
    const {
      accountType,
      isSuperuser,
      objectId,
      username,
    } = authorizeResponse.data;
    localStorage.setItem('token', payload.body.bearerToken.split(' ')[1]);
    yield put(userAuthenticationsActionCreators.authorized({
      accountType,
      isSuperuser,
      objectId,
      username,
    }));
    yield put(userAuthenticationsActionCreators.incrementAuthorizeDraw({ response: authorizeResponse.message }));
    yield put(userAuthenticationsActionCreators.authorizing({ loading: false }));
  } catch (error) {
    yield put(userAuthenticationsActionCreators.decrementAuthorizeDraw({ response: error.message }));
    yield put(userAuthenticationsActionCreators.authorizing({ loading: false }));
    localStorage.removeItem('token');
    yield put(userAuthenticationsActionCreators.deauthorize());
  }
};
const requestRefresh = function* ({ payload }) {
  try {
    yield put(userAuthenticationsActionCreators.refreshing({ loading: true }));
    const { response: refreshResponse } = yield call(serviceMap['request-refresh'], payload);
    yield put(userAuthenticationsActionCreators.incrementRefreshDraw({ response: refreshResponse.message }));
    yield put(userAuthenticationsActionCreators.refreshing({ loading: false }));
  } catch (error) {
    yield put(userAuthenticationsActionCreators.decrementRefreshDraw({ response: error.message }));
    yield put(userAuthenticationsActionCreators.refreshing({ loading: false }));
  }
};
const watchRequestAuthenticate = function* () {
  yield takeEvery(userAuthenticationActionTypes.AUTHENTICATE, requestAuthenticate);
};
const watchRequestAuthorize = function* () {
  yield takeEvery(userAuthenticationActionTypes.AUTHORIZE, requestAuthorize);
};
const watchRequestRefresh = function* () {
  yield takeEvery(userAuthenticationActionTypes.REFRESH, requestRefresh);
};
const sagaWatchers = {
  watchRequestAuthenticate,
  watchRequestAuthorize,
  watchRequestRefresh,
};
export default sagaWatchers;
