/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';
import memoize from 'lodash.memoize';

const paymentInRecordsRecentByPaymentSystemIdProcessedSelector = createSelector(
  state => state.reducers.paymentInRecordsRecent,
  paymentInRecordsRecent => memoize(
    paymentSystemId => paymentInRecordsRecent.data.filter(input => input.toPaymentSystemId === paymentSystemId),
  ),
);
export default { paymentInRecordsRecentByPaymentSystemIdProcessedSelector };
