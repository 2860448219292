/**
 * Node modules
 */
import React from 'react';

/**
 * Common
 */
import {

  renderLazyTest,
  renderLazyActivity,
  renderLazyCentralSheetRecord,
  renderLazyDashboard,
  renderLazyExternalParty,
  renderLazyExternalPartyInformation,
  renderLazyManualRecord,
  renderLazyPaymentInRecord,
  renderLazyPaymentInRecordProcess,
  renderLazyPaymentOutRecord,
  renderLazyPaymentOutRecordProcess,
  renderLazyPaymentSystem,
  renderLazyPaymentSystemCategory,
  renderLazyPaymentSystemCombinedTransaction,
  renderLazyPaymentSystemStatementDetail,
  renderLazyPaymentSystemTransactionCount,
  renderLazyProfitLossSheet,
  renderLazyProfitLossSheetDatum,
  renderLazyProfitLossSheetDatumCustom,
  renderLazyRelationship,
  renderLazySpecialManualRecord,
  renderLazyTransferRecord,
  renderLazyTranslation,
  renderLazyUser,
  renderLazyUserInformation,
} from './lazy-render';

/**
 * Components
 */
import {
  MenuApplicationIcon,
  MenuCentralSheetRecordIcon,
  MenuExternalPartyIcon,
  MenuFinanceAnalysisIcon,
  MenuPaymentSystemIcon,
  MenuPrivilegeIcon,
  MenuProcessIcon,
  MenuProfitLossSheetIcon,
  MenuRelationshipIcon,
  MenuUserIcon,
} from '../components/Icons';

const data = Object.freeze({
  children: [
    {
      children: [
        {
          children: [],
          name: 'title.sidebar.activities',
          path: 'activities',
          permission: 'PAGE_ACTIVITY',
          render: renderLazyActivity,
        },
        {
          children: [],
          name: 'title.sidebar.translations',
          path: 'translations',
          permission: 'PAGE_TRANSLATION',
          render: renderLazyTranslation,
        },
      ],
      icon: (<MenuApplicationIcon />),
      name: 'title.sidebar.applications',
      path: 'app',
    },
    {
      children: [
        {
          children: [
            {
              name: 'title.sidebar.information',
              path: ':username',
              permission: 'PAGE_USER_INFORMATION',
              render: renderLazyUserInformation,
            },
          ],
          name: 'title.sidebar.manage',
          path: '',
          permission: 'PAGE_USER',
          render: renderLazyUser,
        },
      ],
      icon: (<MenuUserIcon />),
      name: 'title.sidebar.users',
      path: 'users',
    },
    {
      children: [
        {
          children: [
            {
              name: 'title.sidebar.information',
              path: ':username',
              permission: 'PAGE_EXTERNAL_PARTY_INFORMATION',
              render: renderLazyExternalPartyInformation,
            },
          ],
          name: 'title.sidebar.manage',
          path: '',
          permission: 'PAGE_EXTERNAL_PARTY',
          render: renderLazyExternalParty,
        },
      ],
      icon: (<MenuExternalPartyIcon />),
      name: 'title.sidebar.externalParties',
      path: 'external-parties',
    },
    {
      children: [
        {
          name: 'title.sidebar.manage',
          path: '',
          permission: 'PAGE_PAYMENT_SYSTEM',
          render: renderLazyPaymentSystem,
        },
        {
          name: 'title.sidebar.transactions',
          path: 'transaction',
          permission: 'PAGE_PAYMENT_SYSTEM_COMBINED_TRANSACTION',
          render: renderLazyPaymentSystemCombinedTransaction,
        },
        {
          name: 'title.sidebar.categories',
          path: 'categories',
          permission: 'PAGE_PAYMENT_SYSTEM_CATEGORY',
          render: renderLazyPaymentSystemCategory,
        },
      ],
      icon: (<MenuPaymentSystemIcon />),
      name: 'title.sidebar.paymentSystems',
      path: 'payment-systems',
    },
    {
      children: [
        {
          name: 'title.sidebar.manage',
          path: '',
          permission: 'PAGE_RELATIONSHIP',
          render: renderLazyRelationship,
        },
      ],
      icon: (<MenuRelationshipIcon />),
      name: 'title.sidebar.relationships',
      path: 'relationships',
    },
    {
      children: [
        {
          children: [
            {
              name: 'title.sidebar.data',
              path: ':date(:?[0-9]{2}-[0-9]{2}-[0-9]{4})/:saleType(malay|thai|grand dragon|hanoi|lao|g1|g2|g3|g4|g5|g6)',
              permission: 'PAGE_PROFIT_LOSS_SHEET',
              render: renderLazyProfitLossSheetDatum,
            },
          ],
          name: 'title.sidebar.manage',
          path: '',
          permission: 'PAGE_PROFIT_LOSS_SHEET',
          render: renderLazyProfitLossSheet,
        },
        {
          name: 'title.sidebar.test',
          path: 'test',
          permission: 'PAGE_PROFIT_LOSS_SHEET',
          render: renderLazyTest,
        },
      ],
      icon: (<MenuProfitLossSheetIcon />),
      name: 'title.sidebar.profitLossSheets',
      path: 'profit-loss-sheets',
    },
    {
      children: [
        {
          name: 'title.sidebar.centralSheetRecord',
          path: 'central-sheet-records',
          permission: 'PAGE_CENTRAL_SHEET_RECORD',
          render: renderLazyCentralSheetRecord,
        },
        {
          name: 'title.sidebar.manualRecord',
          path: 'manual-records',
          permission: 'PAGE_MANUAL_RECORD',
          render: renderLazyManualRecord,
        },
        {
          name: 'title.sidebar.specialManualRecord',
          path: 'special-manual-records',
          permission: 'PAGE_SPECIAL_MANUAL_RECORD',
          render: renderLazySpecialManualRecord,
        },
        {
          name: 'title.sidebar.transferRecord',
          path: 'transfer-record',
          permission: 'PAGE_TRANSFER_RECORD',
          render: renderLazyTransferRecord,
        },
        {
          name: 'title.sidebar.paymentInRecord',
          path: 'payment-in-records',
          permission: 'PAGE_PAYMENT_IN_RECORD',
          render: renderLazyPaymentInRecord,
        },
        {
          name: 'title.sidebar.paymentOutRecord',
          path: 'payment-out-records',
          permission: 'PAGE_PAYMENT_OUT_RECORD',
          render: renderLazyPaymentOutRecord,
        },
      ],
      icon: (<MenuCentralSheetRecordIcon />),
      name: 'title.sidebar.centralSheetRecords',
      path: 'central-sheet-records',
    },
    {
      children: [
        {
          name: 'title.sidebar.processPaymentInRecords',
          path: 'payment-in-records',
          permission: 'PAGE_PAYMENT_IN_RECORD_PROCESS',
          render: renderLazyPaymentInRecordProcess,
        },
        {
          name: 'title.sidebar.processPaymentOutRecords',
          path: 'payment-out-records',
          permission: 'PAGE_PAYMENT_OUT_RECORD_PROCESS',
          render: renderLazyPaymentOutRecordProcess,
        },
      ],
      icon: (<MenuProcessIcon />),
      name: 'title.sidebar.processes',
      path: 'processes',
    },
    {
      children: [
        {
          name: 'title.sidebar.transactionCounts',
          path: 'payment-system-transaction-counts',
          permission: 'PAGE_PAYMENT_SYSTEM_TRANSACTION_COUNT',
          render: renderLazyPaymentSystemTransactionCount,
        },
        {
          name: 'title.sidebar.statementDetails',
          path: 'payment-system-statement-details',
          permission: 'PAGE_PAYMENT_SYSTEM_STATEMENT_DETAIL',
          render: renderLazyPaymentSystemStatementDetail,
        },
      ],
      icon: (<MenuFinanceAnalysisIcon />),
      name: 'title.sidebar.financeAnalysis',
      path: 'finance-analysis',
    },
    {
      children: [
        {
          name: 'title.sidebar.custom',
          path: 'profit-loss-sheet-datum-custom',
          permission: 'PAGE_PROFIT_LOSS_SHEET_DATUM_CUSTOM',
          render: renderLazyProfitLossSheetDatumCustom,
        },
      ],
      icon: (<MenuPrivilegeIcon />),
      name: 'title.sidebar.privilege',
      path: 'privilege',
    },
  ],
  icon: (<MenuProcessIcon />),
  name: 'title.sidebar.dashboard',
  path: '',
  render: renderLazyDashboard,
  renderMenu: false,
});
export default data;
