const getExternalPartiesScopes = [
  'EXTERNAL_PARTIES_DEACTIVATED',
  'EXTERNAL_PARTIES_FOR_EXTERNAL_PARTY_RELATIONSHIP_EXTERNAL_PARTY_INPUT',
  'EXTERNAL_PARTIES_FOR_INPUT',
  'EXTERNAL_PARTIES_REMOVED',
];
const chains = [];
chains.push({ _operation: 'activateExternalPartyById', api: '/external-party/:objectId/activate', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'createExternalParty', api: '/external-party', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'deactivateExternalPartyById', api: '/external-party/:objectId/deactivate', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'getExternalParties', api: '/external-parties', defaultData: [], httpVerb: 'get', scopes: getExternalPartiesScopes });
chains.push({ _operation: 'getExternalPartyByUsername', api: '/external-party/:username', defaultData: null, httpVerb: 'get' });
chains.push({ _operation: 'recoverExternalPartyById', api: '/external-party/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeExternalPartyById', api: '/external-party/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateExternalPartyById', api: '/external-party/:objectId', defaultData: null, httpVerb: 'patch' });
chains.push({ _operation: 'updateExternalPartyCurrencyById', api: '/external-party/:objectId/currency', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateExternalPartyHardBalanceLimitById', api: '/external-party/:objectId/hard-balance-limit', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateExternalPartyUsernameById', api: '/external-party/:objectId/username', defaultData: null, httpVerb: 'put' });
export default chains;
