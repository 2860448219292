/**
 * Node modules
 */
import { addLocaleData } from 'react-intl';

/**
 * Providers
 */
import languageEn from './entries/en-US';
import languageTh from './entries/th-TH';

const appLocale = {
  'en-US': {
    data: languageEn,
    name: 'english',
  },
  'th-TH': {
    data: languageTh,
    name: 'thai',
  },
};
const getLocale = (languageName) => {
  const keys = Object.keys(appLocale);
  let selectedKey = keys[0];
  keys.forEach((key) => {
    if (appLocale[key].name === languageName) {
      selectedKey = key;
    }
  });
  return selectedKey;
};
addLocaleData([...appLocale['en-US'].data.appLocaleData, ...appLocale['th-TH'].data.appLocaleData]);
export {
  getLocale,
};
export default appLocale;
