/**
 * Node modules
 */
import io from 'socket.io-client';

/**
 * Endpoints
 */
import endpointMap from '../endpoints';

const socketEvents = [
  'activities-created',
  'external-parties-created',
  'external-parties-updated',
  'external-party-line-usernames-created',
  'external-party-line-usernames-updated',
  'external-party-malay-properties-created',
  'external-party-malay-properties-updated',
  'external-party-grand-dragon-properties-created',
  'external-party-grand-dragon-properties-updated',
  'external-party-hanoi-properties-created',
  'external-party-hanoi-properties-updated',
  'external-party-lao-properties-created',
  'external-party-lao-properties-updated',


  'external-party-g1-properties-created',
  'external-party-g1-properties-updated',
  'external-party-g2-properties-created',
  'external-party-g2-properties-updated',
  'external-party-g3-properties-created',
  'external-party-g3-properties-updated',
  'external-party-g4-properties-created',
  'external-party-g4-properties-updated',
  'external-party-g5-properties-created',
  'external-party-g5-properties-updated',
  'external-party-g6-properties-created',
  'external-party-g6-properties-updated',

  'external-party-payment-systems-created',
  'external-party-payment-systems-updated',
  'external-party-phone-numbers-created',
  'external-party-phone-numbers-updated',
  'external-party-primary-payment-systems-created',
  'external-party-primary-payment-systems-updated',
  'external-party-relationships-created',
  'external-party-relationships-deleted',
  'external-party-sale-type-metadata-created',
  'external-party-sale-type-metadata-updated',
  'external-party-thai-properties-created',
  'external-party-thai-properties-updated',
  'manual-record-reviews-created',
  'manual-records-created',
  'manual-records-updated',
  'payment-in-record-details-created',
  'payment-in-record-details-updated',
  'payment-in-records-created',
  'payment-in-records-updated',
  'payment-out-record-detail-groups-created',
  'payment-out-record-detail-groups-updated',
  'payment-out-record-details-created',
  'payment-out-record-details-updated',
  'payment-out-records-created',
  'payment-out-records-updated',
  'payment-system-manual-transactions-created',
  'payment-system-manual-transactions-updated',
  'payment-system-relationships-created',
  'payment-system-relationships-deleted',
  'payment-system-transaction-categories-created',
  'payment-system-transaction-categories-updated',
  'payment-system-transactions-created',
  'payment-system-transactions-updated',
  'payment-systems-created',
  'payment-systems-updated',
  'profit-loss-sheet-data-created',
  'profit-loss-sheet-data-updated',
  'profit-loss-sheet-external-party-relationships-created',
  'profit-loss-sheet-external-party-relationships-updated',
  'profit-loss-sheet-user-relationships-created',
  'profit-loss-sheet-user-relationships-updated',
  'profit-loss-sheets-created',
  'profit-loss-sheets-updated',
  'special-manual-records-created',
  'special-manual-records-updated',
  'transfer-records-created',
  'transfer-records-updated',
  'translations-updated',
  'user-line-usernames-created',
  'user-line-usernames-updated',
  'user-payment-system-relationships-deleted',
  'user-permissions-created',
  'user-permissions-updated',
  'user-phone-numbers-created',
  'user-phone-numbers-updated',
  'user-relationships-created',
  'user-relationships-deleted',
  'user-sessions-created',
  'user-sessions-updated',
  'users-created',
  'users-updated',
];
const socketMap = {};
Object.keys(endpointMap).forEach((endpointKey) => {
  const {
    host,
    port,
    protocol,
  } = endpointMap[endpointKey];
  socketMap[endpointKey] = io(`${protocol}://${host}:${port}`);
});
export { socketEvents, socketMap };
