/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';
import memoize from 'lodash.memoize';

/**
 * Utilities
 */
import utilities from '../../../../utilities/general';

const externalPartyRelationshipTreeStructureByExternalPartyIdProcessedSelector = createSelector(
  state => state.reducers.externalPartyRelationships,
  externalPartyRelationships => memoize(
    externalPartyId => utilities.generateTreeStructure(externalPartyRelationships.data.filter(externalPartyRelationship => externalPartyRelationship.externalPartyId === externalPartyId)),
  ),
);
export default { externalPartyRelationshipTreeStructureByExternalPartyIdProcessedSelector };
