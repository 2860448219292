const getProfitLossSheetDataByProfitLossSheetIdScopes = [
  'PROFIT_LOSS_SHEET_DATA_FOR_INPUT_BY_PROFIT_LOSS_SHEET_ID',
  'PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID',
];
const chains = [];
chains.push({ _operation: 'getProfitLossSheetDataByProfitLossSheetId', api: '/profit-loss-sheet-data/:profitLossSheetId', defaultData: [], httpVerb: 'get', scopes: getProfitLossSheetDataByProfitLossSheetIdScopes });
chains.push({ _operation: 'getProfitLossSheetDatumById', api: '/profit-loss-sheet-datum/:objectId', defaultData: null, httpVerb: 'get' });
chains.push({ _operation: 'updateProfitLossSheetDataByProfitLossSheetId', api: '/profit-loss-sheet-data/:profitLossSheetId', defaultData: null, httpVerb: 'patch' });
chains.push({ _operation: 'updateProfitLossSheetDatumById', api: '/profit-loss-sheet-datum/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
