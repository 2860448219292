/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Styles
 */
import { RootEntry, RootGlobalStyle, RootWrapper } from './index.style';

export default class Root extends React.PureComponent {
  static propTypes = { children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired };

  render() {
    const { children } = this.props;
    return (
      <RootWrapper>
        <RootGlobalStyle />
        <RootEntry>
          {children}
        </RootEntry>
      </RootWrapper>
    );
  }
}
