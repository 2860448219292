/**
 * Endpoints
 */
import appActivitiesChains from './apps/app-activities';
import appTranslationsChains from './apps/app-translations';
import centralSheetRecordsChains from './central-sheet-records/central-sheet-records';
import debtSheetRecordsChains from './central-sheet-records/debt-sheet-records';
import externalPartiesChains from './external-parties/external-parties';
import externalPartyLineUsernamesChains from './external-parties/external-party-line-usernames';
import externalPartyMalayPropertiesChains from './external-parties/external-party-malay-properties';
import externalPartyGrandDragonPropertiesChains from './external-parties/external-party-grand-dragon-properties';
import externalPartyHanoiPropertiesChains from './external-parties/external-party-hanoi-properties';
import externalPartyLaoPropertiesChains from './external-parties/external-party-lao-properties';


import externalPartyG1PropertiesChains from './external-parties/external-party-g1-properties';
import externalPartyG2PropertiesChains from './external-parties/external-party-g2-properties';
import externalPartyG3PropertiesChains from './external-parties/external-party-g3-properties';
import externalPartyG4PropertiesChains from './external-parties/external-party-g4-properties';
import externalPartyG5PropertiesChains from './external-parties/external-party-g5-properties';
import externalPartyG6PropertiesChains from './external-parties/external-party-g6-properties';
import externalPartyPaymentSystemsChains from './external-parties/external-party-payment-systems';
import externalPartyPhoneNumbersChains from './external-parties/external-party-phone-numbers';
import externalPartyRelationshipsChains from './external-parties/external-party-relationships';
import externalPartySaleTypeMetadataChains from './external-parties/external-party-sale-type-metadata';
import externalPartyThaiPropertiesChains from './external-parties/external-party-thai-properties';
import manualRecordsChains from './central-sheet-records/manual-records';
import paymentInRecordsChains from './central-sheet-records/payment-in-records';
import paymentOutRecordDetailGroupsChains from './central-sheet-records/payment-out-record-detail-groups';
import paymentOutRecordsChains from './central-sheet-records/payment-out-records';
import paymentSystemRelationshipsChains from './payment-systems/payment-system-relationships';
import paymentSystemsChains from './payment-systems/payment-systems';
import paymentSystemStatementDetailsChains from './payment-systems/payment-system-statement-details';
import paymentSystemTransactionCategoriesChains from './payment-systems/payment-system-transaction-categories';
import paymentSystemTransactionCategoryStatementDetailsChains from './payment-systems/payment-system-transaction-category-statement-details';
import paymentSystemTransactionCountsChains from './payment-systems/payment-system-transaction-counts';
import profitLossSheetDataChains from './central-sheet-records/profit-loss-sheet-data';
import profitLossSheetsChains from './central-sheet-records/profit-loss-sheets';
import profitLossSheetStatementDetailsChains from './central-sheet-records/profit-loss-sheet-statement-details';
import specialManualRecordsChains from './central-sheet-records/special-manual-records';
import specialTransferRecordsChains from './central-sheet-records/special-transfer-records';
import transferRecordsChains from './central-sheet-records/transfer-records';
import userAuthenticationsChains from './authentications/user-authentications';
import userLineUsernamesChains from './users/user-line-usernames';
import userPhoneNumbersChains from './users/user-phone-numbers';
import userRelationshipsChains from './users/user-relationships';
import usersChains from './users/users';
import userSessionsChains from './users/user-sessions';

/**
 * Permissions
 */
import userPermissionsChains from './users/user-permissions';

/**
 * Redux actions
 */
import paymentSystemCombinedTransactionsChains from './payment-systems/payment-system-combined-transactions';
import paymentSystemManualTransactionsChains from './payment-systems/payment-system-manual-transactions';
import paymentSystemTransactionsChains from './payment-systems/payment-system-transactions';

export default {
  apps: {
    chainMap: {
      appActivitiesChains,
      appTranslationsChains,
    },
    host: '8888ACC.com',
    port: 40443,
    protocol: 'https',
  },
  authentications: {
    chainMap: {
      userAuthenticationsChains,
    },
    host: '8888ACC.com',
    port: 41443,
    protocol: 'https',
  },
  centralSheetRecords: {
    chainMap: {
      centralSheetRecordsChains,
      debtSheetRecordsChains,
      manualRecordsChains,
      paymentInRecordsChains,
      paymentOutRecordDetailGroupsChains,
      paymentOutRecordsChains,
      profitLossSheetDataChains,
      profitLossSheetsChains,
      profitLossSheetStatementDetailsChains,
      specialManualRecordsChains,
      specialTransferRecordsChains,
      transferRecordsChains,
    },
    host: '8888ACC.com',
    port: 42443,
    protocol: 'https',
  },
  externalParties: {
    chainMap: {
      externalPartiesChains,
      externalPartyLineUsernamesChains,
      externalPartyMalayPropertiesChains,
      externalPartyPaymentSystemsChains,
      externalPartyGrandDragonPropertiesChains,
      externalPartyHanoiPropertiesChains,
      externalPartyLaoPropertiesChains,
      externalPartyG1PropertiesChains,
      externalPartyG2PropertiesChains,
      externalPartyG3PropertiesChains,
      externalPartyG4PropertiesChains,
      externalPartyG5PropertiesChains,
      externalPartyG6PropertiesChains,
      externalPartyPhoneNumbersChains,
      externalPartyRelationshipsChains,
      externalPartySaleTypeMetadataChains,
      externalPartyThaiPropertiesChains,
    },
    host: '8888ACC.com',
    port: 43443,
    protocol: 'https',
  },
  paymentSystems: {
    chainMap: {
      paymentSystemCombinedTransactionsChains,
      paymentSystemManualTransactionsChains,
      paymentSystemRelationshipsChains,
      paymentSystemsChains,
      paymentSystemStatementDetailsChains,
      paymentSystemTransactionCategoriesChains,
      paymentSystemTransactionCategoryStatementDetailsChains,
      paymentSystemTransactionCountsChains,
      paymentSystemTransactionsChains,
    },
    host: '8888ACC.com',
    port: 44443,
    protocol: 'https',
  },
  users: {
    chainMap: {
      userLineUsernamesChains,
      userPermissionsChains,
      userPhoneNumbersChains,
      userRelationshipsChains,
      usersChains,
      userSessionsChains,
    },
    host: '8888ACC.com',
    port: 45443,
    protocol: 'https',
  },
};
