/**
 * Node modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';

/**
 * Redux actions
 */
import {
  actionCreators as userPermissionsActionCreators,
  actionTypes as userPermissionActionTypes,
} from './actions';

/**
 * Services
 */
import serviceMap from '../../../../services';

const requestGetCurrentUserPermissions = function* ({ payload }) {
  try {
    yield put(userPermissionsActionCreators.gettingCurrentUserPermissions({ loading: true }));
    const {
      headers,
      response,
    } = yield call(serviceMap['request-get-user-permissions-by-user-id'], payload);
    const { data } = response;
    yield put(userPermissionsActionCreators.gotCurrentUserPermissions({
      data,
      headers: {
        limit: headers['x-pagination-per-page'],
        page: headers['x-pagination-current-page'],
        scope: headers['x-scope'],
        search: headers['x-search'],
        sort: headers['x-pagination-sort'],
        total: headers['x-pagination-total-count'],
      },
    }));
    yield put(userPermissionsActionCreators.incrementGetCurrentUserPermissionsDraw({ response: response.message }));
    yield put(userPermissionsActionCreators.gettingCurrentUserPermissions({ loading: false }));
  } catch (error) {
    yield put(userPermissionsActionCreators.decrementGetCurrentUserPermissionsDraw({ response: error.message }));
  } finally {
    yield put(userPermissionsActionCreators.gettingCurrentUserPermissions({ loading: false }));
  }
};
const watchRequestGetCurrentUserPermissions = function* () {
  yield takeEvery(userPermissionActionTypes.GET_CURRENT_USER_PERMISSIONS, requestGetCurrentUserPermissions);
};
const sagaWatchers = { watchRequestGetCurrentUserPermissions };
export default sagaWatchers;
