const getProfitLossSheetsScopes = [
  'PROFIT_LOSS_SHEETS_FOR_INPUT',
  'PROFIT_LOSS_SHEETS_FOR_PAYMENT_OUT_RECORD_INPUT',
  'PROFIT_LOSS_SHEETS_REMOVED',
  'PROFIT_LOSS_SHEETS_UNLOCKED',
  'PROFIT_LOSS_SHEETS_UNVERIFIED',
];
const chains = [];
chains.push({ _operation: 'createProfitLossSheet', api: '/profit-loss-sheet', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getProfitLossSheetByDateAndSaleType', api: '/profit-loss-sheet/:date/:saleType', defaultData: null, httpVerb: 'get' });
chains.push({ _operation: 'getProfitLossSheetExternalParties', api: '/profit-loss-sheet-external-parties', defaultData: [], httpVerb: 'get' });
chains.push({ _operation: 'getProfitLossSheets', api: '/profit-loss-sheets', defaultData: [], httpVerb: 'get', scopes: getProfitLossSheetsScopes });
chains.push({ _operation: 'lockProfitLossSheetById', api: '/profit-loss-sheet/:objectId/lock', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'printProfitLossSheetById', api: '/profit-loss-sheet/:objectId/print', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'recoverProfitLossSheetById', api: '/profit-loss-sheet/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'refreshProfitLossSheetRelationshipsById', api: '/profit-loss-sheet/:objectId/relationships/refresh', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeProfitLossSheetById', api: '/profit-loss-sheet/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'unlockProfitLossSheetById', api: '/profit-loss-sheet/:objectId/unlock', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'verifyProfitLossSheetById', api: '/profit-loss-sheet/:objectId/verify', defaultData: null, httpVerb: 'put' });
export default chains;
