/**
 * Redux actions
 */
import { actionTypes } from './actions';

const handlers = {
  [actionTypes.APPROVING_PAYMENT_OUT_RECORD_BY_ID]: (state, { payload }) => {
    const {
      loading,
      objectId,
    } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isApprovingPaymentOutRecordById: {
          ...state.loading.isApprovingPaymentOutRecordById,
          [objectId]: loading,
        },
      },
    };
  },
  [actionTypes.DECREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW]: (state, { payload }) => {
    const {
      objectId,
      response,
    } = payload;
    const previous = state.request.approvePaymentOutRecordById[objectId];
    return {
      ...state,
      request: {
        ...state.request,
        approvePaymentOutRecordById: {
          ...state.request.approvePaymentOutRecordById,
          [objectId]: {
            ...state.request.approvePaymentOutRecordById[objectId],
            draw: !previous ? -1 : previous.draw - 1,
            response,
          },
        },
      },
    };
  },
  [actionTypes.DECREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW]: (state, { payload }) => {
    const {
      objectId,
      response,
    } = payload;
    const previous = state.request.rejectPaymentOutRecordById[objectId];
    return {
      ...state,
      request: {
        ...state.request,
        rejectPaymentOutRecordById: {
          ...state.request.rejectPaymentOutRecordById,
          [objectId]: {
            ...state.request.rejectPaymentOutRecordById[objectId],
            draw: !previous ? -1 : previous.draw - 1,
            response,
          },
        },
      },
    };
  },
  [actionTypes.INCREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW]: (state, { payload }) => {
    const {
      objectId,
      response,
    } = payload;
    const previous = state.request.approvePaymentOutRecordById[objectId];
    return {
      ...state,
      request: {
        ...state.request,
        approvePaymentOutRecordById: {
          ...state.request.approvePaymentOutRecordById,
          [objectId]: {
            ...state.request.approvePaymentOutRecordById[objectId],
            draw: !previous ? 1 : previous.draw + 1,
            response,
          },
        },
      },
    };
  },
  [actionTypes.INCREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW]: (state, { payload }) => {
    const {
      objectId,
      response,
    } = payload;
    const previous = state.request.rejectPaymentOutRecordById[objectId];
    return {
      ...state,
      request: {
        ...state.request,
        rejectPaymentOutRecordById: {
          ...state.request.rejectPaymentOutRecordById,
          [objectId]: {
            ...state.request.rejectPaymentOutRecordById[objectId],
            draw: !previous ? 1 : previous.draw + 1,
            response,
          },
        },
      },
    };
  },
  [actionTypes.REJECTING_PAYMENT_OUT_RECORD_BY_ID]: (state, { payload }) => {
    const {
      loading,
      objectId,
    } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isRejectingPaymentOutRecordById: {
          ...state.loading.isRejectingPaymentOutRecordById,
          [objectId]: loading,
        },
      },
    };
  },
};
const initialState = {
  loading: {
    isApprovingPaymentOutRecordById: {},
    isRejectingPaymentOutRecordById: {},
  },
  request: {
    approvePaymentOutRecordById: {},
    rejectPaymentOutRecordById: {},
  },
};
export {
  handlers,
  initialState,
};
