const currentUserSelector = state => state.reducers.currentUser;
const tokenSelector = state => state.reducers.token;
const isAuthenticatingSelector = state => state.reducers.loading.isAuthenticating;
const isAuthorizingSelector = state => state.reducers.loading.isAuthorizing;
const isRefreshingSelector = state => state.reducers.loading.isRefreshing;
const authenticateRequestSelector = state => state.reducers.request.authenticate;
const authorizeRequestSelector = state => state.reducers.request.authorize;
const refreshRequestSelector = state => state.reducers.request.refresh;
export default {
  authenticateRequestSelector,
  authorizeRequestSelector,
  currentUserSelector,
  isAuthenticatingSelector,
  isAuthorizingSelector,
  isRefreshingSelector,
  refreshRequestSelector,
  tokenSelector,
};
