/**
 * Node modules
 */
import { all, call, put, takeEvery } from 'redux-saga/effects';

/**
 * Redux actions
 */
import {
  actionCreators as paymentOutRecordDetailGroupsActionCreators,
  actionTypes as paymentOutRecordDetailGroupsActionTypes,
} from './actions';
import {
  actionCreators as paymentOutRecordsActionCreators,
} from '../payment-out-record/actions';

/**
 * Services
 */
import serviceMap from '../../../../services';

const requestApprovePaymentOutRecordDetailGroup = function* ({ payload }) {
  const { body } = payload;
  const { paymentOutRecordDetails } = body;
  try {
    yield put(paymentOutRecordDetailGroupsActionCreators.approvingPaymentOutRecordDetailGroup({ loading: true }));
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.approvingPaymentOutRecordById({
      loading: true,
      objectId: paymentOutRecordDetail.paymentOutRecordId,
    }))));
    const { response: approvePaymentOutRecordDetailGroupResponse } = yield call(serviceMap['request-approve-payment-out-record-detail-group'], payload);
    yield put(paymentOutRecordDetailGroupsActionCreators.incrementApprovePaymentOutRecordDetailGroupDraw({ response: approvePaymentOutRecordDetailGroupResponse.message }));
    yield put(paymentOutRecordDetailGroupsActionCreators.approvingPaymentOutRecordDetailGroup({ loading: false }));
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.incrementApprovePaymentOutRecordByIdDraw({
      objectId: paymentOutRecordDetail.paymentOutRecordId,
      response: approvePaymentOutRecordDetailGroupResponse.message,
    }))));
  } catch (error) {
    yield put(paymentOutRecordDetailGroupsActionCreators.decrementApprovePaymentOutRecordDetailGroupDraw({ response: error.message }));
    yield put(paymentOutRecordDetailGroupsActionCreators.approvingPaymentOutRecordDetailGroup({ loading: false }));
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.decrementApprovePaymentOutRecordByIdDraw({
      objectId: paymentOutRecordDetail.paymentOutRecordId,
      response: error.message,
    }))));
  } finally {
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.approvingPaymentOutRecordById({
      loading: false,
      objectId: paymentOutRecordDetail.paymentOutRecordId,
    }))));
  }
};
const requestRejectPaymentOutRecordDetailGroup = function* ({ payload }) {
  const { body } = payload;
  const { paymentOutRecordDetails } = body;
  try {
    yield put(paymentOutRecordDetailGroupsActionCreators.approvingPaymentOutRecordDetailGroup({ loading: true }));
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.approvingPaymentOutRecordById({
      loading: true,
      objectId: paymentOutRecordDetail.paymentOutRecordId,
    }))));
    const { response: rejectPaymentOutRecordDetailGroupResponse } = yield call(serviceMap['request-reject-payment-out-record-detail-group'], payload);
    yield put(paymentOutRecordDetailGroupsActionCreators.incrementRejectPaymentOutRecordDetailGroupDraw({ response: rejectPaymentOutRecordDetailGroupResponse.message }));
    yield put(paymentOutRecordDetailGroupsActionCreators.approvingPaymentOutRecordDetailGroup({ loading: false }));
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.incrementRejectPaymentOutRecordByIdDraw({
      objectId: paymentOutRecordDetail.paymentOutRecordId,
      response: rejectPaymentOutRecordDetailGroupResponse.message,
    }))));
  } catch (error) {
    yield put(paymentOutRecordDetailGroupsActionCreators.decrementRejectPaymentOutRecordDetailGroupDraw({ response: error.message }));
    yield put(paymentOutRecordDetailGroupsActionCreators.approvingPaymentOutRecordDetailGroup({ loading: false }));
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.decrementRejectPaymentOutRecordByIdDraw({
      objectId: paymentOutRecordDetail.paymentOutRecordId,
      response: error.message,
    }))));
  } finally {
    yield all(paymentOutRecordDetails.map(paymentOutRecordDetail => put(paymentOutRecordsActionCreators.approvingPaymentOutRecordById({
      loading: false,
      objectId: paymentOutRecordDetail.paymentOutRecordId,
    }))));
  }
};
const watchRequestApprovePaymentOutRecordDetailGroup = function* () {
  yield takeEvery(paymentOutRecordDetailGroupsActionTypes.APPROVE_PAYMENT_OUT_RECORD_DETAIL_GROUP, requestApprovePaymentOutRecordDetailGroup);
};
const watchRequestRejectPaymentOutRecordDetailGroup = function* () {
  yield takeEvery(paymentOutRecordDetailGroupsActionTypes.REJECT_PAYMENT_OUT_RECORD_DETAIL_GROUP, requestRejectPaymentOutRecordDetailGroup);
};
const sagaWatchers = {
  watchRequestApprovePaymentOutRecordDetailGroup,
  watchRequestRejectPaymentOutRecordDetailGroup,
};
export default sagaWatchers;
