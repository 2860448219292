/**
 * Redux actions
 */
import { actionTypes } from './actions';

/**
 * Utilities
 */
import { camelCase } from '../../../../utilities/string';
import { CURRENCY_RATE_THAI_BASE } from '../../../../utilities/constant';

const handlers = {
  [actionTypes.CHANGE_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_FORM_BY_PROFIT_LOSS_SHEET_ID]: (state, { payload }) => {
    const {
      name,
      profitLossSheetId,
      value,
    } = payload;
    const [
      managerId,
      workerId,
      externalPartyId,
      externalPartyMode,
      saleMode,
      moneyMode,
    ] = name.split('#');
    const number = value;
    const key = `${managerId}#${workerId}#${externalPartyId}#${externalPartyMode}#${saleMode}#${moneyMode}`;
    const profitLossSheetDataForUpdateForm = {
      ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId],
      changedDataMap: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].changedDataMap,
      },
      changeTracker: state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].changeTracker,
      dataMap: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].dataMap,
      },
      initialDataMap: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].initialDataMap,
      },
    };
    const initialValue = profitLossSheetDataForUpdateForm.initialDataMap[key];
    const previousValue = profitLossSheetDataForUpdateForm.dataMap[key];
    if (previousValue === initialValue && number !== initialValue) {
      profitLossSheetDataForUpdateForm.changeTracker -= 1;
      profitLossSheetDataForUpdateForm.changedDataMap[key] -= 1;
    } else if (previousValue !== initialValue && number === initialValue) {
      profitLossSheetDataForUpdateForm.changeTracker += 1;
      profitLossSheetDataForUpdateForm.changedDataMap[key] += 1;
    }
    const rate = profitLossSheetDataForUpdateForm.dataMap[`${externalPartyId}#Rate`];
    profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${moneyMode}`] += (number - previousValue) * rate;
    profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#${moneyMode}`] += (number - previousValue) * rate;
    profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#${moneyMode}`] += (number - previousValue) * rate;
    profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#${moneyMode}`] += (number - previousValue) * rate;
    profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#${moneyMode}`] += (number - previousValue) * rate;
    profitLossSheetDataForUpdateForm.dataMap[key] = number;
    return {
      ...state,
      profitLossSheetDataForUpdateFormByProfitLossSheetId: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId,
        [profitLossSheetId]: profitLossSheetDataForUpdateForm,
      },
    };
  },
  [actionTypes.DECREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW]: (state, { payload }) => {
    const {
      profitLossSheetId,
      response,
    } = payload;
    const previous = state.request.updateProfitLossSheetDataByProfitLossSheetId[profitLossSheetId];
    return {
      ...state,
      request: {
        ...state.request,
        updateProfitLossSheetDataByProfitLossSheetId: {
          ...state.request.updateProfitLossSheetDataByProfitLossSheetId,
          [profitLossSheetId]: {
            ...state.request.updateProfitLossSheetDataByProfitLossSheetId[profitLossSheetId],
            draw: !previous ? -1 : previous.draw - 1,
            response,
          },
        },
      },
    };
  },
  [actionTypes.GOT_PROFIT_LOSS_SHEET_ASSORTED_DATA_BY_PROFIT_LOSS_SHEET_ID]: (state, { payload }) => {
    const {
      assortedData,
      profitLossSheetId,
    } = payload;
    const previousForm = state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId] || {
      changedDataMap: {},
      changeTracker: 0,
      dataMap: {},
      initialDataMap: {},
    };
    const profitLossSheetDataForUpdateForm = {
      changedDataMap: {},
      changeTracker: 0,
      dataMap: {},
      initialDataMap: {},
    };
    [
      'Customer#Paper',
      'Customer#Website',
      'Partner#Paper',
      'Partner#Website',
    ].forEach((compositeData) => {
      const [externalPartyMode, saleMode] = compositeData.split('#');
      const key = `${camelCase(externalPartyMode)}${saleMode}`;
      const { data } = assortedData[key] || [];
      data.forEach((datum) => {
        const {
          currency,
          externalPartyId,
          managerId,
          objectId,
          workerId,
        } = datum;
        const moneyInKey = `${managerId}#${workerId}#${externalPartyId}#${externalPartyMode}#${saleMode}#MoneyIn`;
        const moneyOutKey = `${managerId}#${workerId}#${externalPartyId}#${externalPartyMode}#${saleMode}#MoneyOut`;
        const hasMoneyInChanged = previousForm.changedDataMap[moneyInKey] !== undefined && previousForm.changedDataMap[moneyInKey] !== 0;
        const hasMoneyOutChanged = previousForm.changedDataMap[moneyOutKey] !== undefined && previousForm.changedDataMap[moneyOutKey] !== 0;
        const moneyIn = hasMoneyInChanged ? previousForm.dataMap[moneyInKey] : datum[`${key}MoneyInAmount`];
        const moneyOut = hasMoneyOutChanged ? previousForm.dataMap[moneyOutKey] : datum[`${key}MoneyOutAmount`];
        if (!profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#MoneyIn`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#MoneyIn`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#MoneyOut`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#MoneyOut`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#MoneyIn`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#MoneyIn`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#MoneyOut`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#MoneyOut`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#MoneyIn`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#MoneyIn`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#MoneyOut`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#MoneyOut`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#MoneyIn`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#MoneyIn`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#MoneyOut`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#MoneyOut`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#MoneyIn`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#MoneyIn`] = 0;
        }
        if (!profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#MoneyOut`]) {
          profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#MoneyOut`] = 0;
        }
        const rate = CURRENCY_RATE_THAI_BASE[currency];
        profitLossSheetDataForUpdateForm.dataMap[`${externalPartyId}#Rate`] = rate;
        profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#MoneyIn`] += moneyIn * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${saleMode}#MoneyOut`] += moneyOut * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#MoneyIn`] += moneyIn * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#MoneyOut`] += moneyOut * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#MoneyIn`] += moneyIn * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${externalPartyMode}#${saleMode}#MoneyOut`] += moneyOut * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#MoneyIn`] += moneyIn * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${externalPartyMode}#${saleMode}#MoneyOut`] += moneyOut * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#MoneyIn`] += moneyIn * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyMode}#${saleMode}#MoneyOut`] += moneyOut * rate;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyId}#Id`] = objectId;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyId}#${externalPartyMode}#${saleMode}#MoneyIn`] = moneyIn;
        profitLossSheetDataForUpdateForm.dataMap[`${managerId}#${workerId}#${externalPartyId}#${externalPartyMode}#${saleMode}#MoneyOut`] = moneyOut;
        profitLossSheetDataForUpdateForm.initialDataMap[moneyInKey] = hasMoneyInChanged ? previousForm.initialDataMap[moneyInKey] : moneyIn;
        profitLossSheetDataForUpdateForm.initialDataMap[moneyOutKey] = hasMoneyOutChanged ? previousForm.initialDataMap[moneyOutKey] : moneyOut;
        profitLossSheetDataForUpdateForm.changedDataMap[moneyInKey] = hasMoneyInChanged ? previousForm.changedDataMap[moneyInKey] : 0;
        profitLossSheetDataForUpdateForm.changedDataMap[moneyOutKey] = hasMoneyOutChanged ? previousForm.changedDataMap[moneyOutKey] : 0;
        profitLossSheetDataForUpdateForm.changeTracker += profitLossSheetDataForUpdateForm.changedDataMap[moneyInKey] + profitLossSheetDataForUpdateForm.changedDataMap[moneyOutKey];
      });
    });
    return {
      ...state,
      profitLossSheetDataForUpdateFormByProfitLossSheetId: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId,
        [profitLossSheetId]: profitLossSheetDataForUpdateForm,
      },
    };
  },
  [actionTypes.GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_STRUCTURE_BY_PROFIT_LOSS_SHEET_ID]: (state, { payload }) => {
    const {
      profitLossSheetDataForUpdateStructure,
      profitLossSheetId,
    } = payload;
    return {
      ...state,
      profitLossSheetDataForUpdateStructureByProfitLossSheetId: {
        ...state.profitLossSheetDataForUpdateStructureByProfitLossSheetId,
        [profitLossSheetId]: profitLossSheetDataForUpdateStructure,
      },
    };
  },
  [actionTypes.INCREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW]: (state, { payload }) => {
    const {
      profitLossSheetId,
      response,
    } = payload;
    const previous = state.request.updateProfitLossSheetDataByProfitLossSheetId[profitLossSheetId];
    return {
      ...state,
      request: {
        ...state.request,
        updateProfitLossSheetDataByProfitLossSheetId: {
          ...state.request.updateProfitLossSheetDataByProfitLossSheetId,
          [profitLossSheetId]: {
            ...state.request.updateProfitLossSheetDataByProfitLossSheetId[profitLossSheetId],
            draw: !previous ? +1 : previous.draw + 1,
            response,
          },
        },
      },
    };
  },
  [actionTypes.UPDATED_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID]: (state, { payload }) => {
    const { profitLossSheetId } = payload;
    const form = {
      changedDataMap: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].changedDataMap,
      },
      changeTracker: state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].changeTracker,
      dataMap: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].dataMap,
      },
      initialDataMap: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId].initialDataMap,
      },
    };
    Object.keys(form.changedDataMap).forEach((key) => {
      form.changedDataMap[key] = 0;
    });
    form.changeTracker = 0;
    return {
      ...state,
      profitLossSheetDataForUpdateFormByProfitLossSheetId: {
        ...state.profitLossSheetDataForUpdateFormByProfitLossSheetId,
        [profitLossSheetId]: form,
      },
    };
  },
  [actionTypes.UPDATING_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID]: (state, { payload }) => {
    const {
      loading,
      profitLossSheetId,
    } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isUpdatingProfitLossSheetDataByProfitLossSheetId: {
          ...state.loading.isUpdatingProfitLossSheetDataByProfitLossSheetId,
          [profitLossSheetId]: loading,
        },
      },
    };
  },
};
const initialState = {
  profitLossSheetDataForUpdateFormByProfitLossSheetId: {},
  profitLossSheetDataForUpdateStructureByProfitLossSheetId: {},
};
export {
  handlers,
  initialState,
};
