/**
 * Node modules
 */
import { conformToMask } from 'react-text-mask';
import _camelCase from 'lodash.camelcase';
import _kebabCase from 'lodash.kebabcase';

/**
 * Utilities
 */
import {
  getAmountMask,
  getIdentifierMask,
  getPercentMask,
} from './text-mask';

const camelCase = string => _camelCase(string);
const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
const getCompoundKey = args => args.join('#');
const kebabCase = string => _kebabCase(string);
const transformAmount = (amount, currency, fixed = 2) => {
  const defaultValue = '0.00';
  if (amount !== 0 && !amount) {
    return defaultValue;
  }
  const masked = conformToMask(
    amount.toFixed(fixed).toString(),
    getAmountMask(currency),
    { guide: false },
  ).conformedValue;
  if (!masked) {
    return defaultValue;
  }
  return masked;
};
const transformCount = (count) => {
  const defaultValue = '0';
  if (count !== 0 && !count) {
    return defaultValue;
  }
  const masked = conformToMask(
    count.toString(),
    getAmountMask(),
    { guide: false },
  ).conformedValue;
  if (!masked) {
    return defaultValue;
  }
  return masked;
};
const transformIdentifier = (identifier) => {
  if (!identifier) {
    return '';
  }
  const masked = conformToMask(
    identifier,
    getIdentifierMask(),
    { guide: false },
  ).conformedValue;
  if (!masked) {
    return '';
  }
  return masked;
};
const transformPercent = (percent) => {
  const defaultValue = '0.00%';
  if (percent !== 0 && !percent) {
    return defaultValue;
  }
  const masked = conformToMask(
    percent.toFixed(2).toString(),
    getPercentMask(),
    { guide: false },
  ).conformedValue;
  if (!masked) {
    return defaultValue;
  }
  return masked;
};
const unmaskAmount = (amount) => {
  const defaultValue = 0;
  if (!amount) {
    return defaultValue;
  }
  const unmasked = Number(amount.toString().replace(/[^0-9.-]/g, ''));
  if (!unmasked) {
    return defaultValue;
  }
  return unmasked;
};
const unmaskIdentifier = (identifier) => {
  const defaultValue = '';
  if (!identifier) {
    return defaultValue;
  }
  const unmasked = identifier.replace(/-/g, '');
  if (!unmasked) {
    return defaultValue;
  }
  return unmasked;
};
const unmaskPercent = (percent) => {
  const defaultValue = 0;
  if (!percent) {
    return defaultValue;
  }
  const unmasked = Number(percent.toString().replace(/[^0-9.-]/g, ''));
  if (!unmasked) {
    return defaultValue;
  }
  return unmasked;
};
export {
  camelCase,
  capitalize,
  getCompoundKey,
  kebabCase,
  transformAmount,
  transformCount,
  transformIdentifier,
  transformPercent,
  unmaskAmount,
  unmaskIdentifier,
  unmaskPercent,
};
