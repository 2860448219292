/**
 * Redux actions
 */
import { actionTypes } from './actions';

/**
 * Settings
 */
import { languageConfig } from '../../../../settings';

const DESKTOP_VIEW = 'DesktopView';
const MOBILE_VIEW = 'MobileView';
const TABLET_VIEW = 'TabletView';
const getView = (width) => {
  let view = MOBILE_VIEW;
  if (width > 1220) {
    view = DESKTOP_VIEW;
  } else if (width > 767) {
    view = TABLET_VIEW;
  }
  return view;
};
const initialView = getView(window.innerWidth);
const handlers = {
  [actionTypes.CHANGE_LANGUAGE]: (state, { payload }) => {
    const { language } = payload;
    return {
      ...state,
      language,
    };
  },
  [actionTypes.CHANGE_OPEN_KEYS]: (state, { payload }) => {
    const { openKeys } = payload;
    return {
      ...state,
      openKeys,
    };
  },
  [actionTypes.CHANGE_SELECTED_KEYS]: (state, { payload }) => {
    const { selectedKeys } = payload;
    return {
      ...state,
      selectedKeys,
    };
  },
  [actionTypes.CHANGE_WINDOW_SIZE]: (state, { payload }) => {
    const {
      height,
      width,
    } = payload;
    const view = getView(width);
    const isSidebarCollapsed = view !== DESKTOP_VIEW;
    return {
      ...state,
      height,
      isSidebarClosed: isSidebarCollapsed && !state.isOpeningSidebar,
      isSidebarCollapsed,
      view,
      width,
    };
  },
  [actionTypes.TOGGLE_COLLAPSE_SIDEBAR]: (state, { payload }) => {
    const { isSidebarCollapsed } = payload;
    return {
      ...state,
      isSidebarClosed: isSidebarCollapsed && !state.isOpeningSidebar,
      isSidebarCollapsed,
    };
  },
  [actionTypes.TOGGLE_OPENING_SIDEBAR]: (state, { payload }) => {
    const { isOpeningSidebar } = payload;
    return {
      ...state,
      isOpeningSidebar,
      isSidebarClosed: state.isSidebarCollapsed && !isOpeningSidebar,
    };
  },
};
const initialState = {
  height: window.innerHeight,
  isOpeningSidebar: false,
  isSidebarClosed: initialView !== DESKTOP_VIEW,
  isSidebarCollapsed: initialView !== DESKTOP_VIEW,
  language: localStorage.getItem('language') || languageConfig.main,
  openKeys: [`/${window.location.pathname.split('/')[1]}`],
  selectedKeys: [window.location.pathname],
  view: initialView,
  width: window.innerWidth,
};
export {
  handlers,
  initialState,
};
