const getPaymentSystemsScopes = [
  'PAYMENT_SYSTEMS_ARCHIVED',
  'PAYMENT_SYSTEMS_FOR_PAYMENT_SYSTEM_RELATIONSHIP_PAYMENT_SYSTEM_INPUT',
  'PAYMENT_SYSTEMS_FROM_FOR_INPUT',
  'PAYMENT_SYSTEMS_REMOVED',
  'PAYMENT_SYSTEMS_TO_FOR_INPUT',
];
const chains = [];
chains.push({ _operation: 'archivePaymentSystemById', api: '/payment-system/:objectId/archive', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'createPaymentSystem', api: '/payment-system', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getPaymentSystems', api: '/payment-systems', defaultData: [], httpVerb: 'get', scopes: getPaymentSystemsScopes });
chains.push({ _operation: 'recoverPaymentSystemById', api: '/payment-system/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removePaymentSystemById', api: '/payment-system/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'unarchivePaymentSystemById', api: '/payment-system/:objectId/unarchive', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updatePaymentSystemById', api: '/payment-system/:objectId', defaultData: null, httpVerb: 'patch' });
chains.push({ _operation: 'updatePaymentSystemIdentifierById', api: '/payment-system/:objectId/identifier', defaultData: null, httpVerb: 'patch' });
chains.push({ _operation: 'updatePaymentSystemInitialBalanceById', api: '/payment-system/:objectId/initial-balance', defaultData: null, httpVerb: 'put' });
export default chains;
