/**
 * Helpers
 */
import { createActionCreator } from '../../helpers';

const actionTypes = {
  DECREMENT_PRINT_PROFIT_LOSS_SHEET_BY_ID_DRAW: 'DECREMENT_PRINT_PROFIT_LOSS_SHEET_BY_ID_DRAW',
  INCREMENT_PRINT_PROFIT_LOSS_SHEET_BY_ID_DRAW: 'INCREMENT_PRINT_PROFIT_LOSS_SHEET_BY_ID_DRAW',
  PRINT_PROFIT_LOSS_SHEET_BY_ID: 'PRINT_PROFIT_LOSS_SHEET_BY_ID',
  PRINTING_PROFIT_LOSS_SHEET_BY_ID: 'PRINTING_PROFIT_LOSS_SHEET_BY_ID',
};
const actionCreators = {
  decrementPrintProfitLossSheetByIdDraw: createActionCreator(actionTypes.DECREMENT_PRINT_PROFIT_LOSS_SHEET_BY_ID_DRAW),
  incrementPrintProfitLossSheetByIdDraw: createActionCreator(actionTypes.INCREMENT_PRINT_PROFIT_LOSS_SHEET_BY_ID_DRAW),
  printingProfitLossSheetById: createActionCreator(actionTypes.PRINTING_PROFIT_LOSS_SHEET_BY_ID),
  printProfitLossSheetById: createActionCreator(actionTypes.PRINT_PROFIT_LOSS_SHEET_BY_ID),
};
export {
  actionCreators,
  actionTypes,
};
