/**
 * Node modules
 */
import React from 'react';

/**
 * Styles
 */
import { OverlayContent, OverlayIcon, OverlayWrapper } from './index.style';

const Overlay = () => (
  <OverlayWrapper>
    <OverlayContent>
      <OverlayIcon
        spin
        type="loading"
      />
    </OverlayContent>
  </OverlayWrapper>
);
export default Overlay;
