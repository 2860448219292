/**
 * Node modules
 */
import 'moment/locale/th';
import 'intl';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { LocaleProvider } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Route,
  Switch,
} from 'react-router';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * Components
 */
import Overlay from '../components/Overlay';

/**
 * Providers
 */
import appLocale, { getLocale } from '../providers/language';

/**
 * Redux actions
 */
import actionCreators from '../redux/actions';

/**
 * Redux selectors
 */
import selectors from '../redux/selectors';

/**
 * Utilities
 */
import * as customPropTypes from '../utilities/custom-prop-types';
import memoizeHelpers from '../utilities/memoize-helpers';
import utilities from '../utilities/general';

/**
 * Constant variables
 */
const LazyAuthorizeContainer = lazy(() => import('./AuthorizeContainer'));
const LazyUserLoginContainer = lazy(() => import('./UserLoginContainer'));
moment.updateLocale('th', {
  meridiem: (hour) => {
    if (hour < 12) {
      return 'AM';
    }
    return 'PM';
  },
});

class AppEntry extends React.PureComponent {
  static defaultProps = { token: null };

  static propTypes = {
    appTranslationsGlobal: customPropTypes.data.isRequired,
    dispatchGetAppTranslationsGlobal: PropTypes.func.isRequired,
    history: customPropTypes.history.isRequired,
    language: PropTypes.string.isRequired,
    serverAppLocaleData: PropTypes.objectOf(PropTypes.any).isRequired,
    token: PropTypes.string,
  };

  componentDidMount() {
    const {
      dispatchGetAppTranslationsGlobal,
    } = this.props;
    dispatchGetAppTranslationsGlobal({
      query: {
        limit: 'all',
        page: '1',
        scope: 'APP_TRANSLATIONS_GLOBAL',
        sort: 'translationId:asc',
      },
    });
  }

  componentDidUpdate(previousProps) {
    const {
      appTranslationsGlobal,
      dispatchGetAppTranslationsGlobal,
    } = this.props;
    const hasDataChanged = utilities.hasDataChanged(this.props, previousProps);
    if (hasDataChanged) {
      dispatchGetAppTranslationsGlobal({
        query: {
          ...appTranslationsGlobal.headers,
        },
      });
    }
  }

  render() {
    const {
      history,
      language,
      serverAppLocaleData,
      token,
    } = this.props;
    const currentAppLocale = appLocale[getLocale(language)].data;
    const serverAppLocale = serverAppLocaleData[language === 'raw' ? 'raw' : getLocale(language)];
    const resolvedMessages = memoizeHelpers.resolvedMessages({
      currentAppLocale,
      serverAppLocale,
    });
    const to = {
      pathname: '/login',
      state: {
        from: history.location,
      },
    };
    const redirect = (
      <Redirect to={to} />
    );
    moment.locale(currentAppLocale.locale);
    const overlay = (<Overlay />);
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={resolvedMessages}
        >
          <ConnectedRouter history={history}>
            <Suspense fallback={overlay}>
              <Switch>
                <Route
                  component={LazyUserLoginContainer}
                  exact
                  path="/login"
                />
                {token ? (<Route component={LazyAuthorizeContainer} />) : redirect}
              </Switch>
            </Suspense>
          </ConnectedRouter>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

const {
  getAppTranslationsGlobal,
} = actionCreators;
const {
  appTranslationsGlobalSelector,
  languageSelector,
  serverAppLocaleDataSelector,
  tokenSelector,
  translationsUpdatedBroadcastSocketEventSelector,
} = selectors;
const mapStateToProps = state => ({
  appTranslationsGlobal: appTranslationsGlobalSelector(state),
  language: languageSelector(state),
  serverAppLocaleData: serverAppLocaleDataSelector(state),
  token: tokenSelector(state),
  translationsUpdatedBroadcastSocketEvent: translationsUpdatedBroadcastSocketEventSelector(state),
});
const mapDispatchToProps = {
  dispatchGetAppTranslationsGlobal: getAppTranslationsGlobal,
};
export default connect(mapStateToProps, mapDispatchToProps)(
  AppEntry,
);
