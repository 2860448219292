const getUserPhoneNumbersScopes = [
  'USER_PHONE_NUMBERS_REMOVED',
];
const chains = [];
chains.push({ _operation: 'createUserPhoneNumber', api: '/user/:userId/phone-number', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getUserPhoneNumbersByUserId', api: '/user/:userId/phone-numbers', defaultData: [], httpVerb: 'get', scopes: getUserPhoneNumbersScopes });
chains.push({ _operation: 'recoverUserPhoneNumberById', api: '/user/:userId/phone-number/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeUserPhoneNumberById', api: '/user/:userId/phone-number/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateUserPhoneNumberById', api: '/user/:userId/phone-number/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
