/**
 * Node modules
 */
import { Provider } from 'react-redux';
import React from 'react';

/**
 * Components
 */
import Root from './components/Root';

/**
 * Containers
 */
import AppEntry from './containers/AppEntry';

/**
 * Redux store
 */
import { history, store } from './redux/store';

const App = () => (
  <Provider store={store}>
    <Root>
      <AppEntry history={history} />
    </Root>
  </Provider>
);
export default App;
