/**
 * Node modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';

/**
 * Redux actions
 */
import {
  actionCreators as profitLossSheetActionCreators,
  actionTypes as profitLossSheetActionTypes,
} from './actions';

/**
 * Services
 */
import serviceMap from '../../../../services';

const requestPrintProfitLossSheetById = function* ({ payload }) {
  const { objectId } = payload;
  try {
    yield put(profitLossSheetActionCreators.printingProfitLossSheetById({
      loading: true,
      objectId,
    }));
    const { response: printProfitLossSheetByIdResponse } = yield call(serviceMap['request-print-profit-loss-sheet-by-id'], payload);
    yield put(profitLossSheetActionCreators.incrementPrintProfitLossSheetByIdDraw({
      objectId,
      response: printProfitLossSheetByIdResponse.message,
    }));
    yield put(profitLossSheetActionCreators.printingProfitLossSheetById({
      loading: false,
      objectId,
    }));
    import('../../../../utilities/pdf-make').then((exported) => {
      const pdfMake = exported.default;
      pdfMake.tableLayouts = {
        tableLayout: {
          paddingBottom: () => 0,
          paddingTop: () => 0,
        },
      };
      pdfMake.createPdf({
        ...printProfitLossSheetByIdResponse.data,
        footer: (currentPage, pageCount) => ({
          columns: [
            {
              alignment: 'center',
              fontSize: 14,
              text: [
                { text: `${currentPage} / ${pageCount}` },
              ],
            },
          ],
        }),
      }).print({}, window);
    });
  } catch (error) {
    yield put(profitLossSheetActionCreators.decrementPrintProfitLossSheetByIdDraw({
      objectId,
      response: error.message,
    }));
    yield put(profitLossSheetActionCreators.printingProfitLossSheetById({
      loading: false,
      objectId,
    }));
  }
};
const watchRequestPrintProfitLossSheetById = function* () {
  yield takeEvery(profitLossSheetActionTypes.PRINT_PROFIT_LOSS_SHEET_BY_ID, requestPrintProfitLossSheetById);
};
const sagaWatchers = { watchRequestPrintProfitLossSheetById };
export default sagaWatchers;
