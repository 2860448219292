/**
 * Redux actions
 */
import { actionTypes } from './actions';

const handlers = {
  [actionTypes.DECREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        getCurrentUserPermissions: {
          ...state.request.getCurrentUserPermissions,
          draw: state.request.getCurrentUserPermissions.draw - 1,
          response,
        },
      },
    };
  },
  [actionTypes.GETTING_CURRENT_USER_PERMISSIONS]: (state, { payload }) => {
    const { loading } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isGettingCurrentUserPermissions: loading,
      },
    };
  },
  [actionTypes.GOT_CURRENT_USER_PERMISSIONS]: (state, { payload }) => {
    const {
      data,
      headers,
    } = payload;
    return {
      ...state,
      currentUserPermissions: {
        ...state.currentUserPermissions,
        data,
        headers,
      },
    };
  },
  [actionTypes.INCREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        getCurrentUserPermissions: {
          ...state.request.getCurrentUserPermissions,
          draw: state.request.getCurrentUserPermissions.draw + 1,
          response,
        },
      },
    };
  },
};
const initialState = {
  currentUserPermissions: {
    data: [],
    headers: {},
  },
  loading: {
    isGettingCurrentUserPermissions: false,
  },
  request: {
    getCurrentUserPermissions: {
      draw: 0,
      response: '',
    },
  },
};
export {
  handlers,
  initialState,
};
