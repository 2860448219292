/**
 * Node modules
 */
import { createSelector } from 'reselect';

const heightSelector = state => state.reducers.height;
const isOpeningSidebarSelector = state => state.reducers.isOpeningSidebar;
const isSidebarClosedSelector = state => state.reducers.isSidebarClosed;
const isSidebarCollapsedSelector = state => state.reducers.isSidebarCollapsed;
const languageSelector = state => state.reducers.language;
const openKeysSelector = state => state.reducers.openKeys;
const selectedKeysSelector = state => state.reducers.selectedKeys;
const viewSelector = state => state.reducers.view;
const serverAppLocaleDataSelector = createSelector(
  state => state.reducers.appTranslationsGlobal,
  (appTranslations) => {
    const localeData = {
      'en-US': {
        messages: {},
      },
      'raw': {
        messages: {},
      },
      'th-TH': {
        messages: {},
      },
    };
    appTranslations.data.forEach((appTranslation) => {
      const {
        enUs,
        thTh,
        translationId,
      } = appTranslation;
      localeData.raw.messages[translationId] = translationId;
      localeData['en-US'].messages[translationId] = enUs;
      localeData['th-TH'].messages[translationId] = thTh;
    });
    return localeData;
  },
);
export default {
  heightSelector,
  isOpeningSidebarSelector,
  isSidebarClosedSelector,
  isSidebarCollapsedSelector,
  languageSelector,
  openKeysSelector,
  selectedKeysSelector,
  serverAppLocaleDataSelector,
  viewSelector,
};
