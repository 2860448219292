/**
 * Redux actions
 */
import { actionTypes } from './actions';

const handlers = {
  [actionTypes.AUTHENTICATED]: (state, { payload }) => {
    const { token } = payload;
    return {
      ...state,
      token,
    };
  },
  [actionTypes.AUTHENTICATING]: (state, { payload }) => {
    const { loading } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isAuthenticating: loading,
      },
    };
  },
  [actionTypes.AUTHORIZED]: (state, { payload }) => {
    const {
      accountType,
      isSuperuser,
      objectId,
      username,
    } = payload;
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        accountType,
        isAuthorized: true,
        isSuperuser,
        objectId,
        username,
      },
    };
  },
  [actionTypes.AUTHORIZING]: (state, { payload }) => {
    const { loading } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isAuthorizing: loading,
      },
    };
  },
  [actionTypes.DEAUTHORIZE]: state => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      accountType: '',
      isAuthorized: false,
      isSuperuser: false,
      objectId: '',
      username: '',
    },
    token: null,
  }),
  [actionTypes.DECREMENT_AUTHENTICATE_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        authenticate: {
          ...state.request.authenticate,
          draw: state.request.authenticate.draw - 1,
          response,
        },
      },
    };
  },
  [actionTypes.DECREMENT_AUTHORIZE_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        authorize: {
          ...state.request.authorize,
          draw: state.request.authorize.draw - 1,
          response,
        },
      },
    };
  },
  [actionTypes.DECREMENT_REFRESH_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        refresh: {
          ...state.request.refresh,
          draw: state.request.refresh.draw - 1,
          response,
        },
      },
    };
  },
  [actionTypes.INCREMENT_AUTHENTICATE_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        authenticate: {
          ...state.request.authenticate,
          draw: state.request.authenticate.draw + 1,
          response,
        },
      },
    };
  },
  [actionTypes.INCREMENT_AUTHORIZE_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        authorize: {
          ...state.request.authorize,
          draw: state.request.authorize.draw + 1,
          response,
        },
      },
    };
  },
  [actionTypes.INCREMENT_REFRESH_DRAW]: (state, { payload }) => {
    const { response } = payload;
    return {
      ...state,
      request: {
        ...state.request,
        refresh: {
          ...state.request.refresh,
          draw: state.request.refresh.draw + 1,
          response,
        },
      },
    };
  },
  [actionTypes.REFRESHING]: (state, { payload }) => {
    const { loading } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        isRefreshing: loading,
      },
    };
  },
};
const initialState = {
  currentUser: {
    accountType: '',
    isAuthorized: false,
    isSuperuser: false,
    objectId: '',
    username: '',
  },
  token: localStorage.getItem('token'),
};
export {
  handlers,
  initialState,
};
