/**
 * Node modules
 */
import antdLocale from 'antd/lib/locale-provider/th_TH';
import appLocaleData from 'react-intl/locale-data/th';

/**
 * Providers
 */
import messages from '../locales/th-TH.json';

const locale = {
  antd: antdLocale,
  appLocaleData,
  locale: 'th-TH',
  messages: {
    ...messages,
  },
};
export default locale;
