/**
 * Node modules
 */
import PropTypes from 'prop-types';

const data = PropTypes.objectOf(PropTypes.any);
const datum = PropTypes.objectOf(PropTypes.any);
const headers = PropTypes.shape({
  limit: PropTypes.string,
  page: PropTypes.string,
  sort: PropTypes.string,
  total: PropTypes.string,
});
const history = PropTypes.objectOf(PropTypes.any);
const inputs = PropTypes.arrayOf(PropTypes.object);
const location = PropTypes.objectOf(PropTypes.any);
const match = PropTypes.objectOf(PropTypes.any);
const request = PropTypes.shape({
  draw: PropTypes.number,
  response: PropTypes.string,
});
const translate = PropTypes.shape({
  defaultFormats: PropTypes.object,
  defaultLocale: PropTypes.string,
  formatDate: PropTypes.func,
  formatHTMLMessage: PropTypes.func,
  formatMessage: PropTypes.func,
  formatNumber: PropTypes.func,
  formatPlural: PropTypes.func,
  formatRelative: PropTypes.func,
  formats: PropTypes.object,
  formatters: PropTypes.object,
  formatTime: PropTypes.func,
  locale: PropTypes.string,
  messages: PropTypes.object,
  now: PropTypes.func,
  textComponent: PropTypes.string,
});
export {
  data,
  datum,
  headers,
  history,
  inputs,
  location,
  match,
  request,
  translate,
};
