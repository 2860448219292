/**
 * Node modules
 */
import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

const isApprovingPaymentOutRecordByIdSelector = createSelector(
  state => state.reducers.loading.isApprovingPaymentOutRecordById,
  isApprovingPaymentOutRecordById => memoize(
    objectId => (objectId && isApprovingPaymentOutRecordById[objectId]) || false,
  ),
);
const isRejectingPaymentOutRecordByIdSelector = createSelector(
  state => state.reducers.loading.isRejectingPaymentOutRecordById,
  isRejectingPaymentOutRecordById => memoize(
    objectId => (objectId && isRejectingPaymentOutRecordById[objectId]) || false,
  ),
);
const approvePaymentOutRecordByIdRequestSelector = createSelector(
  state => state.reducers.request.approvePaymentOutRecordById,
  approvePaymentOutRecordById => memoize(
    objectId => (objectId && approvePaymentOutRecordById[objectId]) || {
      draw: 0,
      response: '',
    },
  ),
);
const rejectPaymentOutRecordByIdRequestSelector = createSelector(
  state => state.reducers.request.rejectPaymentOutRecordById,
  rejectPaymentOutRecordById => memoize(
    objectId => (objectId && rejectPaymentOutRecordById[objectId]) || {
      draw: 0,
      response: '',
    },
  ),
);
export default {
  approvePaymentOutRecordByIdRequestSelector,
  isApprovingPaymentOutRecordByIdSelector,
  isRejectingPaymentOutRecordByIdSelector,
  rejectPaymentOutRecordByIdRequestSelector,
};
