const getUserLineUsernamesScopes = [
  'USER_LINE_USERNAMES_REMOVED',
];
const chains = [];
chains.push({ _operation: 'createUserLineUsername', api: '/user/:userId/line-username', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getUserLineUsernamesByUserId', api: '/user/:userId/line-usernames', defaultData: [], httpVerb: 'get', scopes: getUserLineUsernamesScopes });
chains.push({ _operation: 'recoverUserLineUsernameById', api: '/user/:userId/line-username/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeUserLineUsernameById', api: '/user/:userId/line-username/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateUserLineUsernameById', api: '/user/:userId/line-username/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
