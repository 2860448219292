/**
 * Node modules
 */
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Root
 */
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App.jsx', () => {
    const NextApp = require('./App').default; // eslint-disable-line global-require
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}
