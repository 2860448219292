/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';

/**
 * Utilities
 */
import utilities from '../../../../utilities/general';

const paymentSystemRelationshipTreeStructureProcessedSelector = createSelector(
  state => state.reducers.paymentSystemRelationships,
  paymentSystemRelationships => utilities.generateTreeStructure(paymentSystemRelationships.data),
);
export default { paymentSystemRelationshipTreeStructureProcessedSelector };
