/**
 * Node modules
 */
import { lazy } from 'react';
const renderLazyTest = () => lazy(() => import('../containers/PageContainer/ProfitLossSheetStatementDetail'));

const renderLazyActivity = () => lazy(() => import('../containers/PageContainer/Activity'));
const renderLazyCentralSheetRecord = () => lazy(() => import('../containers/PageContainer/CentralSheetRecord'));
const renderLazyDashboard = () => lazy(() => import('../containers/PageContainer/Dashboard'));
const renderLazyExternalParty = () => lazy(() => import('../containers/PageContainer/ExternalParty'));
const renderLazyExternalPartyInformation = () => lazy(() => import('../containers/PageContainer/ExternalPartyInformation'));
const renderLazyManualRecord = () => lazy(() => import('../containers/PageContainer/ManualRecord'));
const renderLazyPaymentInRecord = () => lazy(() => import('../containers/PageContainer/PaymentInRecord'));
const renderLazyPaymentInRecordProcess = () => lazy(() => import('../containers/PageContainer/PaymentInRecordProcess'));
const renderLazyPaymentOutRecord = () => lazy(() => import('../containers/PageContainer/PaymentOutRecord'));
const renderLazyPaymentOutRecordProcess = () => lazy(() => import('../containers/PageContainer/PaymentOutRecordProcess'));
const renderLazyPaymentSystem = () => lazy(() => import('../containers/PageContainer/PaymentSystem'));
const renderLazyPaymentSystemCategory = () => lazy(() => import('../containers/PageContainer/PaymentSystemTransactionCategory'));
const renderLazyPaymentSystemCombinedTransaction = () => lazy(() => import('../containers/PageContainer/PaymentSystemCombinedTransaction'));
const renderLazyPaymentSystemStatementDetail = () => lazy(() => import('../containers/PageContainer/PaymentSystemStatementDetail'));
const renderLazyPaymentSystemTransactionCount = () => lazy(() => import('../containers/PageContainer/PaymentSystemTransactionCount'));
const renderLazyProfitLossSheet = () => lazy(() => import('../containers/PageContainer/ProfitLossSheet'));
const renderLazyProfitLossSheetDatum = () => lazy(() => import('../containers/PageContainer/ProfitLossSheetDatum'));
const renderLazyProfitLossSheetDatumCustom = () => lazy(() => import('../containers/PageContainer/ProfitLossSheetDatumCustom'));
const renderLazyRelationship = () => lazy(() => import('../containers/PageContainer/Relationship'));
const renderLazySpecialManualRecord = () => lazy(() => import('../containers/PageContainer/SpecialManualRecord'));
const renderLazyTransferRecord = () => lazy(() => import('../containers/PageContainer/TransferRecord'));
const renderLazyTranslation = () => lazy(() => import('../containers/PageContainer/Translation'));
const renderLazyUser = () => lazy(() => import('../containers/PageContainer/User'));
const renderLazyUserInformation = () => lazy(() => import('../containers/PageContainer/UserInformation'));
export {

  // RenderLazyProfitLossSheetDatum ,
  renderLazyActivity,
  renderLazyCentralSheetRecord,
  renderLazyDashboard,
  renderLazyExternalParty,
  renderLazyExternalPartyInformation,
  renderLazyManualRecord,
  renderLazyPaymentInRecord,
  renderLazyPaymentInRecordProcess,
  renderLazyPaymentOutRecord,
  renderLazyPaymentOutRecordProcess,
  renderLazyPaymentSystem,
  renderLazyPaymentSystemCategory,
  renderLazyPaymentSystemCombinedTransaction,
  renderLazyPaymentSystemStatementDetail,
  renderLazyPaymentSystemTransactionCount,
  renderLazyProfitLossSheet,
  renderLazyProfitLossSheetDatum,
  renderLazyProfitLossSheetDatumCustom,
  renderLazyRelationship,
  renderLazySpecialManualRecord,
  renderLazyTransferRecord,
  renderLazyTranslation,
  renderLazyUser,
  renderLazyUserInformation,
  renderLazyTest,
};
