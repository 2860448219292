/**
 * Node modules
 */
import {
  Ankh,
  Archive,
  ArrowCircleDown,
  ArrowCircleUp,
  Ban,
  BookOpen,
  CaretRight,
  ChartPie,
  Check,
  CheckCircle,
  ChessKing,
  ChessPawn,
  ChessRook,
  Coins,
  Crown,
  Database,
  Donate,
  Dove,
  Edit,
  EnvelopeOpen,
  ExchangeAlt,
  Folder,
  GlobeAmericas,
  HandHoldingUsd,
  Hdd,
  Inbox,
  Info,
  Key,
  Lock,
  Magic,
  Mobile,
  Paperclip,
  Pen,
  Phone,
  Print,
  Question,
  Sitemap,
  SortAmountDown,
  SortAmountUp,
  Star,
  SyncAlt,
  Times,
  TimesCircle,
  ToggleOff,
  ToggleOn,
  TrashAlt,
  TrashRestore,
  Undo,
  University,
  Unlock,
  User,
  Users,
} from 'styled-icons/fa-solid';
import { Line } from 'styled-icons/fa-brands/Line';
import styled from 'styled-components';

const ActivatedIcon = styled(CheckCircle)`
  color: rgba(46, 204, 113, 1);
  height: 18px;
  width: 18px;
`;
const CentralSheetRecordPaperIcon = styled(Paperclip)`
  color: rgba(189, 195, 199, 1);
  height: 12px;
  width: 12px;
`;
const CentralSheetRecordSpecialManualRecordIcon = styled(Star)`
  color: rgba(189, 195, 199, 1);
  height: 12px;
  width: 12px;
`;
const CentralSheetRecordWebsiteIcon = styled(GlobeAmericas)`
  color: rgba(189, 195, 199, 1);
  height: 12px;
  width: 12px;
`;
const DeactivatedIcon = styled(TimesCircle)`
  color: rgba(231, 76, 60, 1);
  height: 18px;
  width: 18px;
`;
const ExternalPartyOverviewEditIcon = styled(Pen)`
  color: rgba(127, 140, 141, 1);
  height: 12px;
  width: 12px;
`;
const FormActivateIcon = styled(Ankh)`
  color: rgba(52, 152, 219, 1);
  height: 18px;
  width: 18px;
`;
const FormApproveIcon = styled(Check)`
  color: rgba(99, 110, 114, 1);
  height: 30px;
  width: 30px;
`;
const FormArchiveIcon = styled(Archive)`
  color: rgba(44, 62, 80, 1);
  height: 18px;
  width: 18px;
`;
const FormClaimIcon = styled(HandHoldingUsd)`
  color: rgba(99, 110, 114, 1);
  height: 30px;
  width: 30px;
`;
const FormDeactivateIcon = styled(Ban)`
  color: rgba(231, 76, 60, 1);
  height: 18px;
  width: 18px;
`;
const FormDeleteIcon = styled(TrashAlt)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormInformationIcon = styled(Info)`
  color: rgba(44, 62, 80, 1);
  height: 18px;
  width: 18px;
`;
const FormLockIcon = styled(Lock)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormOffIcon = styled(ToggleOff)`
  color: rgba(44, 62, 80, 1);
  height: 18px;
  width: 18px;
`;
const FormOnIcon = styled(ToggleOn)`
  color: rgba(44, 62, 80, 1);
  height: 18px;
  width: 18px;
`;
const FormPrimarizeIcon = styled(Star)`
  color: rgba(243, 156, 18, 1);
  height: 18px;
  width: 18px;
`;
const FormPrintIcon = styled(Print)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormRecoverIcon = styled(TrashRestore)`
  color: rgba(44, 62, 80, 1);
  height: 18px;
  width: 18px;
`;
const FormRefreshIcon = styled(SyncAlt)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormRejectIcon = styled(Times)`
  color: rgba(99, 110, 114, 1);
  height: 30px;
  width: 30px;
`;
const FormRemoveIcon = styled(TrashAlt)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormRevertIcon = styled(Undo)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormUnarchiveIcon = styled(EnvelopeOpen)`
  color: rgba(44, 62, 80, 1);
  height: 18px;
  width: 18px;
`;
const FormUnclaimIcon = styled(Undo)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormUnlockIcon = styled(Unlock)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormUpdateIcon = styled(Edit)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const FormVerifyIcon = styled(Check)`
  color: rgba(99, 110, 114, 1);
  height: 18px;
  width: 18px;
`;
const LineIcon = styled(Line)`
  color: rgba(0, 195, 0, 1);
  height: 18px;
  width: 18px;
`;
const MenuApplicationIcon = styled(Hdd)`
  height: 14px;
  width: 14px;
`;
const MenuCentralSheetRecordIcon = styled(Database)`
  height: 14px;
  width: 14px;
`;
const MenuExternalPartyIcon = styled(Users)`
  height: 14px;
  width: 14px;
`;
const MenuFinanceAnalysisIcon = styled(ChartPie)`
  height: 14px;
  width: 14px;
`;
const MenuPaymentSystemIcon = styled(University)`
  height: 14px;
  width: 14px;
`;
const MenuPrivilegeIcon = styled(Star)`
  height: 14px;
  width: 14px;
`;
const MenuProcessIcon = styled(Magic)`
  height: 14px;
  width: 14px;
`;
const MenuProfitLossSheetIcon = styled(BookOpen)`
  height: 14px;
  width: 14px;
`;
const MenuRelationshipIcon = styled(Sitemap)`
  height: 14px;
  width: 14px;
`;
const MenuUserIcon = styled(User)`
  height: 14px;
  width: 14px;
`;
const MobileIcon = styled(Mobile)`
  height: 18px;
  width: 18px;
`;
const NoDataIcon = styled(Inbox)`
  color: rgba(189, 195, 199, 1);
  height: 18px;
  width: 18px;
`;
const PaymentInRecordMoneyIcon = styled(Coins)`
  color: rgba(241, 196, 15, 1);
  height: 30px;
  width: 30px;
`;
const PaymentSystemStatementDetailFeesIcon = styled(Donate)`
  color: rgba(241, 196, 15, 1);
  height: 18px;
  width: 18px;
`;
const PaymentSystemStatementDetailPaymentInIcon = styled(ArrowCircleDown)`
  color: rgba(39, 174, 96, 1);
  height: 18px;
  width: 18px;
`;
const PaymentSystemStatementDetailPaymentOutIcon = styled(ArrowCircleUp)`
  color: rgba(231, 76, 60, 1);
  height: 18px;
  width: 18px;
`;
const PaymentSystemStatementDetailPendingPaymentIn = styled(Question)`
  height: 15px;
  width: 15px;
`;
const PaymentSystemStatementDetailTransactionInIcon = styled(ExchangeAlt)`
  color: rgba(39, 174, 96, 1);
  height: 18px;
  width: 18px;
`;
const PaymentSystemStatementDetailTransactionOutIcon = styled(ExchangeAlt)`
  color: rgba(231, 76, 60, 1);
  height: 18px;
  width: 18px;
`;
const PaymentSystemTransactionCategoryFolderIcon = styled(Folder)`
  height: 14px;
  width: 14px;
`;
const PaymentTransactionArrowIcon = styled(CaretRight)`
  height: 20px;
  width: 20px;
`;
const PendingPaymentOutRecordIcon = styled(Question)`
  height: 30px;
  width: 30px;
`;
const PhoneNumberIcon = styled(Phone)`
  height: 15px;
  width: 15px;
`;
const ProfitLossSheetLockedIcon = styled(Lock)`
  color: rgba(127, 140, 141, 1);
  height: 12px;
  width: 12px;
`;
const ProfitLossSheetUnlockedIcon = styled(Unlock)`
  color: rgba(127, 140, 141, 1);
  height: 12px;
  width: 12px;
`;
const ProfitLossSheetVerifiedIcon = styled(Check)`
  color: rgba(127, 140, 141, 1);
  height: 12px;
  width: 12px;
`;
const RelationshipExternalPartyIcon = styled(ChessPawn)`
  color: rgba(231, 76, 60, 1);
  height: 18px;
  width: 18px;
`;
const RelationshipManagerIcon = styled(ChessKing)`
  color: rgba(52, 73, 94, 1);
  height: 18px;
  width: 18px;
`;
const RelationshipUnassignedIcon = styled(Question)`
  color: rgba(52, 73, 94, 1);
  height: 18px;
  width: 18px;
`;
const RelationshipWorkerIcon = styled(ChessRook)`
  color: rgba(52, 73, 94, 1);
  height: 18px;
  width: 18px;
`;
const SaleModePaperIcon = styled(Paperclip)`
  color: rgba(189, 195, 199, 1);
  height: 18px;
  width: 18px;
`;
const SaleModeWebsiteIcon = styled(GlobeAmericas)`
  color: rgba(189, 195, 199, 1);
  height: 18px;
  width: 18px;
`;
const SidebarIcon = styled(Dove)`
  color: rgba(236, 240, 241, 1);
  height: 20px;
  width: 20px;
`;
const SortAmountDownIcon = styled(SortAmountDown)`
  height: 20px;
  width: 20px;
`;
const SortAmountUpIcon = styled(SortAmountUp)`
  height: 20px;
  width: 20px;
`;
const SuperuserIcon = styled(Crown)`
  color: rgba(241, 196, 15, 1);
  height: 15px;
  width: 15px;
`;
const UserLoginPasswordIcon = styled(Key)`
  color: rgba(127, 140, 141, 1);
  height: 14px;
  width: 14px;
`;
const UserLoginUsernameIcon = styled(User)`
  color: rgba(127, 140, 141, 1);
  height: 14px;
  width: 14px;
`;
export {
  ActivatedIcon,
  CentralSheetRecordPaperIcon,
  CentralSheetRecordSpecialManualRecordIcon,
  CentralSheetRecordWebsiteIcon,
  DeactivatedIcon,
  ExternalPartyOverviewEditIcon,
  FormActivateIcon,
  FormApproveIcon,
  FormArchiveIcon,
  FormClaimIcon,
  FormDeactivateIcon,
  FormDeleteIcon,
  FormInformationIcon,
  FormLockIcon,
  FormOffIcon,
  FormOnIcon,
  FormPrimarizeIcon,
  FormPrintIcon,
  FormRecoverIcon,
  FormRefreshIcon,
  FormRejectIcon,
  FormRemoveIcon,
  FormRevertIcon,
  FormUnarchiveIcon,
  FormUnclaimIcon,
  FormUnlockIcon,
  FormUpdateIcon,
  FormVerifyIcon,
  LineIcon,
  MenuApplicationIcon,
  MenuCentralSheetRecordIcon,
  MenuExternalPartyIcon,
  MenuFinanceAnalysisIcon,
  MenuPaymentSystemIcon,
  MenuPrivilegeIcon,
  MenuProcessIcon,
  MenuProfitLossSheetIcon,
  MenuRelationshipIcon,
  MenuUserIcon,
  MobileIcon,
  NoDataIcon,
  PaymentInRecordMoneyIcon,
  PaymentSystemStatementDetailFeesIcon,
  PaymentSystemStatementDetailPaymentInIcon,
  PaymentSystemStatementDetailPaymentOutIcon,
  PaymentSystemStatementDetailPendingPaymentIn,
  PaymentSystemStatementDetailTransactionInIcon,
  PaymentSystemStatementDetailTransactionOutIcon,
  PaymentSystemTransactionCategoryFolderIcon,
  PaymentTransactionArrowIcon,
  PendingPaymentOutRecordIcon,
  PhoneNumberIcon,
  ProfitLossSheetLockedIcon,
  ProfitLossSheetUnlockedIcon,
  ProfitLossSheetVerifiedIcon,
  RelationshipExternalPartyIcon,
  RelationshipManagerIcon,
  RelationshipUnassignedIcon,
  RelationshipWorkerIcon,
  SaleModePaperIcon,
  SaleModeWebsiteIcon,
  SidebarIcon,
  SortAmountDownIcon,
  SortAmountUpIcon,
  SuperuserIcon,
  UserLoginPasswordIcon,
  UserLoginUsernameIcon,
};
