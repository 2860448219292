/**
 * Node modules
 */
import { createAction } from 'redux-actions';

const actionTypes = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  CHANGE_SELECTED_KEYS: 'CHANGE_SELECTED_KEYS',
  CHANGE_WINDOW_SIZE: 'CHANGE_WINDOW_SIZE',
  TOGGLE_COLLAPSE_SIDEBAR: 'TOGGLE_COLLAPSE_SIDEBAR',
  TOGGLE_OPENING_SIDEBAR: 'TOGGLE_OPENING_SIDEBAR',
};
const actionCreators = {
  changeLanguage: createAction(actionTypes.CHANGE_LANGUAGE),
  changeOpenKeys: createAction(actionTypes.CHANGE_OPEN_KEYS),
  changeSelectedKeys: createAction(actionTypes.CHANGE_SELECTED_KEYS),
  changeWindowSize: createAction(actionTypes.CHANGE_WINDOW_SIZE),
  toggleCollapseSidebar: createAction(actionTypes.TOGGLE_COLLAPSE_SIDEBAR),
  toggleOpeningSidebar: createAction(actionTypes.TOGGLE_OPENING_SIDEBAR),
};
export {
  actionCreators,
  actionTypes,
};
