/**
 * Node modules
 */
import styled, { createGlobalStyle } from 'styled-components';
import antdStyles from 'antd/dist/antd.css';
import nprogressStyles from 'nprogress/nprogress.css';

const RootEntry = styled.div`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  text-rendering: optimizeLegibility;
  > div {
    height: 100%;
  }
  font-family: 'Roboto', sans-serif;
`;
const RootGlobalStyle = createGlobalStyle`
  html, body, #root, #root > div {
    height: 100%;
  }
  ${antdStyles}; /* stylelint-disable-line */
  ${nprogressStyles}; /* stylelint-disable-line */
  .ant-calendar-selected-date .ant-calendar-date {
    background: rgba(24, 144, 255, 1) !important;
  }
  .ant-row {
    .ant-avatar {
      margin-right: 5px;
    }
  }
  .ant-form-item-children {
    .ant-row {
      margin-top: 5px;
      padding-left: 5px;
    }
  }
  .ant-tooltip {
    max-width: 500px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-select-selection-selected-value {
    width: 100%;
  }
  .ant-tree-show-line {
    .ant-tree-switcher.ant-tree-switcher-noop::after{
      border-left: 1px solid rgba(217, 217, 217, 1) !important;
      content: ' ' !important;
      height: 100% !important;
      left: 12px !important;
      position: absolute !important;
      width: 1px !important;
    }
    .ant-tree-switcher.ant-tree-switcher-noop::before{
      border-bottom: 1px solid rgba(217, 217, 217, 1);
      content: ' ';
      height: 38%;
      left: 12px;
      position: absolute;
      width: 16px;
    }
    > li:last-child > .ant-tree-switcher.ant-tree-switcher-noop::before{
      border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
      border-left: 1px solid rgba(217, 217, 217, 1) !important;
      content: ' ' !important;
      display: block;
      height: 38% !important;
      left: 12px !important;
      position: absolute !important;
      width: 16px !important;
    }
    .ant-tree-child-tree > li:last-child > .ant-tree-switcher.ant-tree-switcher-noop::before{
      border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
      border-left: 1px solid rgba(217, 217, 217, 1) !important;
      content: ' ' !important;
      display: block;
      height: 38% !important;
      left: 12px !important;
      position: absolute !important;
      width: 16px !important;
    }
    .ant-tree-switcher-noop {
      > i {
        svg {
          display: none;
        }
      }
    }
    > li:last-child > .ant-tree-switcher.ant-tree-switcher-noop::after {
      display: none !important;
    }
  }
  .ant-dropdown-menu-item {
    padding: 0;
    .ant-btn {
      height: 100%;
    }
    [ant-click-animating-without-extra-node]::after {
      animation: 0s;
    }
    .ant-btn[disabled] {
      background-color: inherit;
    }
    a, button, input[type = 'button'] {
      width: 100%;
      :hover {
        background: none;
        border-color: inherit;
        color: inherit;
      }
      background: none;
      border-style: none;
      border-width: 5px 12px;
      box-shadow: none;
      cursor: pointer;
      font: inherit;
      outline: inherit;
      padding: 12px 15px;
    }
  }
`;
const RootWrapper = styled.div``;
export {
  RootEntry,
  RootGlobalStyle,
  RootWrapper,
};
