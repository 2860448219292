/**
 * Helpers
 */
import { createActionCreator } from '../../helpers';

const actionTypes = {
  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATED: 'AUTHENTICATED',
  AUTHENTICATING: 'AUTHENTICATING',
  AUTHORIZE: 'AUTHORIZE',
  AUTHORIZED: 'AUTHORIZED',
  AUTHORIZING: 'AUTHORIZING',
  DEAUTHORIZE: 'DEAUTHORIZE',
  DECREMENT_AUTHENTICATE_DRAW: 'DECREMENT_AUTHENTICATE_DRAW',
  DECREMENT_AUTHORIZE_DRAW: 'DECREMENT_AUTHORIZE_DRAW',
  DECREMENT_REFRESH_DRAW: 'DECREMENT_REFRESH_DRAW',
  INCREMENT_AUTHENTICATE_DRAW: 'INCREMENT_AUTHENTICATE_DRAW',
  INCREMENT_AUTHORIZE_DRAW: 'INCREMENT_AUTHORIZE_DRAW',
  INCREMENT_REFRESH_DRAW: 'INCREMENT_REFRESH_DRAW',
  REFRESH: 'REFRESH',
  REFRESHING: 'REFRESHING',
};
const actionCreators = {
  authenticate: createActionCreator(actionTypes.AUTHENTICATE),
  authenticated: createActionCreator(actionTypes.AUTHENTICATED),
  authenticating: createActionCreator(actionTypes.AUTHENTICATING),
  authorize: createActionCreator(actionTypes.AUTHORIZE),
  authorized: createActionCreator(actionTypes.AUTHORIZED),
  authorizing: createActionCreator(actionTypes.AUTHORIZING),
  deauthorize: createActionCreator(actionTypes.DEAUTHORIZE),
  decrementAuthenticateDraw: createActionCreator(actionTypes.DECREMENT_AUTHENTICATE_DRAW),
  decrementAuthorizeDraw: createActionCreator(actionTypes.DECREMENT_AUTHORIZE_DRAW),
  decrementRefreshDraw: createActionCreator(actionTypes.DECREMENT_REFRESH_DRAW),
  incrementAuthenticateDraw: createActionCreator(actionTypes.INCREMENT_AUTHENTICATE_DRAW),
  incrementAuthorizeDraw: createActionCreator(actionTypes.INCREMENT_AUTHORIZE_DRAW),
  incrementRefreshDraw: createActionCreator(actionTypes.INCREMENT_REFRESH_DRAW),
  refresh: createActionCreator(actionTypes.REFRESH),
  refreshing: createActionCreator(actionTypes.REFRESHING),
};
export {
  actionCreators,
  actionTypes,
};
