/**
 * Node modules
 */
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

/**
 * Utilities
 */
import { CURRENCY_MAP } from './constant';

const getAmountMask = (currency) => {
  const maskOptions = {
    allowDecimal: true,
    allowLeadingZeroes: true,
    allowNegative: true,
    decimalLimit: 2,
    decimalSymbol: '.',
    includeThousandsSeparator: true,
    prefix: '',
    thousandsSeparatorSymbol: ',',
  };
  const prefix = CURRENCY_MAP[currency];
  if (prefix) {
    maskOptions.prefix = `${prefix} `;
  }
  return createNumberMask(maskOptions);
};
const getIdentifierMask = () => [/\d/, /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
const getPercentMask = () => createNumberMask({
  allowDecimal: true,
  allowLeadingZeroes: true,
  allowNegative: false,
  decimalLimit: 2,
  decimalSymbol: '.',
  includeThousandsSeparator: true,
  prefix: '',
  suffix: '%',
  thousandsSeparatorSymbol: ',',
});
export {
  getAmountMask,
  getIdentifierMask,
  getPercentMask,
};
