/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';
import memoize from 'lodash.memoize';
import _ from 'lodash';

/**
 * Utilities
 */
import utilities from '../../../../utilities/general';

const externalPartyExternalPartyRelationshipInputsProcessedSelector = createSelector(
  state => state.reducers.externalPartiesForExternalPartyRelationshipExternalPartyInput,
  externalPartiesForExternalPartyRelationshipExternalPartyInput => externalPartiesForExternalPartyRelationshipExternalPartyInput.data.map((datum) => {
    const isMalay = utilities.getIsMalay(datum);
    const isGrandDragon = utilities.getIsGrandDragon(datum);
    const isHanoi = utilities.getIsHanoi(datum);
    const isLao = utilities.getIsLao(datum);
    const isG1 = utilities.getIsG1(datum);
    const isG2 = utilities.getIsG2(datum);
    const isG3 = utilities.getIsG3(datum);
    const isG4 = utilities.getIsG4(datum);
    const isG5 = utilities.getIsG5(datum);
    const isG6 = utilities.getIsG6(datum);
    const isCustomer = utilities.getIsCustomer(datum);
    const isPartner = utilities.getIsPartner(datum);
    const isThai = utilities.getIsThai(datum);
    return {
      ...datum,
      isCustomer,
      isMalay,
      isGrandDragon,
      isHanoi,
      isLao,
      isG1,
      isG2,
      isG3,
      isG4,
      isG5,
      isG6,
      isPartner,
      isThai,
      logoName: utilities.getLogoName({
        isCustomer,
        isPartner,
      }),
    };
  }),
);
const externalPartyInputsBySaleTypesProcessedSelector = createSelector(
  state => state.reducers.externalPartiesForInput,
  externalPartiesForInput => memoize(
    (...saleTypes) => externalPartiesForInput.data.map((datum) => {
      const isMalay = utilities.getIsMalay(datum);
      const isGrandDragon = utilities.getIsGrandDragon(datum);
      const isHanoi = utilities.getIsHanoi(datum);
      const isLao = utilities.getIsLao(datum);
      const isG1 = utilities.getIsG1(datum);
      const isG2 = utilities.getIsG2(datum);
      const isG3 = utilities.getIsG3(datum);
      const isG4 = utilities.getIsG4(datum);
      const isG5 = utilities.getIsG5(datum);
      const isG6 = utilities.getIsG6(datum);
      const isCustomer = utilities.getIsCustomer(datum);
      const isPartner = utilities.getIsPartner(datum);
      const isThai = utilities.getIsThai(datum);

      return {
        ...datum,
        isCustomer,
        isMalay,
        isPartner,
        isGrandDragon,
        isHanoi,
        isLao,
        isG1,
        isG2,
        isG3,
        isG4,
        isG5,
        isG6,
        isThai,
        logoName: utilities.getLogoName({
          isCustomer,
          isPartner,
        }),
      };
    }).filter(input => saleTypes.every(saleType => input[`is${_.upperFirst(_.camelCase(saleType))}`])),
  ),
);
const externalPartyInputsProcessedSelector = createSelector(
  state => state.reducers.externalPartiesForInput,
  externalPartiesForInput => externalPartiesForInput.data.map((datum) => {
    const isMalay = utilities.getIsMalay(datum);
    const isCustomer = utilities.getIsCustomer(datum);
    const isGrandDragon = utilities.getIsGrandDragon(datum);
    const isHanoi = utilities.getIsHanoi(datum);
    const isLao = utilities.getIsLao(datum);
    const isG1 = utilities.getIsG1(datum);
    const isG2 = utilities.getIsG2(datum);
    const isG3 = utilities.getIsG3(datum);
    const isG4 = utilities.getIsG4(datum);
    const isG5 = utilities.getIsG5(datum);
    const isG6 = utilities.getIsG6(datum);
    const isPartner = utilities.getIsPartner(datum);
    const isThai = utilities.getIsThai(datum);
    return {
      ...datum,
      isCustomer,
      isGrandDragon,
      isHanoi,
      isLao,
      isG1,
      isG2,
      isG3,
      isG4,
      isG5,
      isG6,
      isMalay,
      isPartner,
      isThai,
      logoName: utilities.getLogoName({
        isCustomer,
        isPartner,
      }),
    };
  }),
);
export default {
  externalPartyExternalPartyRelationshipInputsProcessedSelector,
  externalPartyInputsBySaleTypesProcessedSelector,
  externalPartyInputsProcessedSelector,
};
