/**
 * Node modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';

/**
 * Redux actions
 */
import {
  actionCreators as profitLossSheetDataActionCreators,
  actionTypes as profitLossSheetDataActionTypes,
} from './actions';

/**
 * Services
 */
import serviceMap from '../../../../services';

/**
 * Utilities
 */
import { camelCase } from '../../../../utilities/string';

const requestGetProfitLossSheetDataForUpdateByProfitLossSheetId = function* ({ payload }) {
  const { params } = payload;
  const { profitLossSheetId } = params;
  try {
    yield put(profitLossSheetDataActionCreators.gettingProfitLossSheetDataForUpdateByProfitLossSheetId({
      loading: true,
      profitLossSheetId,
    }));
    const {
      headers,
      response: getProfitLossSheetDataByProfitLossSheetIdResponse,
    } = yield call(serviceMap['request-get-profit-loss-sheet-data-by-profit-loss-sheet-id'], payload);
    const assortedData = {
      customerPaper: {
        data: [],
      },
      customerWebsite: {
        data: [],
      },
      partnerPaper: {
        data: [],
      },
      partnerWebsite: {
        data: [],
      },
    };
    const profitLossSheetDataForUpdateStructure = {
      customerPaper: {
        managerIdMap: new Map(),
      },
      customerWebsite: {
        managerIdMap: new Map(),
      },
      partnerPaper: {
        managerIdMap: new Map(),
      },
      partnerWebsite: {
        managerIdMap: new Map(),
      },
    };
    getProfitLossSheetDataByProfitLossSheetIdResponse.data.forEach((datum) => {
      const {
        isCustomerPaper,
        isCustomerWebsite,
        isPartnerPaper,
        isPartnerWebsite,
      } = datum;
      const modes = [];
      if (isCustomerPaper) {
        modes.push('customerPaper');
      }
      if (isCustomerWebsite) {
        modes.push('customerWebsite');
      }
      if (isPartnerPaper) {
        modes.push('partnerPaper');
      }
      if (isPartnerWebsite) {
        modes.push('partnerWebsite');
      }
      modes.forEach((mode) => {
        const { data } = assortedData[mode];
        data.push(datum);
      });
    });
    [
      'Customer#Paper',
      'Customer#Website',
      'Partner#Paper',
      'Partner#Website',
    ].forEach((compositeData) => {
      const [externalPartyMode, saleMode] = compositeData.split('#');
      const key = `${camelCase(externalPartyMode)}${saleMode}`;
      const { data } = assortedData[key];
      const { managerIdMap } = profitLossSheetDataForUpdateStructure[key];
      data.forEach((datum, index) => {
        const {
          currency,
          externalPartyId,
          externalPartyNickname,
          externalPartyUsername,
          managerId,
          managerNickname,
          managerUsername,
          workerId,
          workerNickname,
          workerUsername,
        } = datum;
        const nextData = index === data.length - 1 ? null : data[index + 1];
        if (!managerIdMap.has(managerId)) {
          managerIdMap.set(managerId, {
            managerId,
            managerNickname,
            managerUsername,
            totalExternalParties: 0,
            workerIdMap: new Map(),
          });
        }
        const { workerIdMap } = managerIdMap.get(managerId);
        managerIdMap.get(managerId).totalExternalParties += 1;
        if (!workerIdMap.has(workerId)) {
          workerIdMap.set(workerId, {
            externalPartyIdMap: new Map(),
            workerId,
            workerNickname,
            workerUsername,
          });
        }
        const { externalPartyIdMap } = workerIdMap.get(workerId);
        if (!externalPartyIdMap.has(externalPartyId)) {
          externalPartyIdMap.set(externalPartyId, {
            currency,
            externalPartyId,
            externalPartyNickname,
            externalPartyUsername,
            lastOfManager: !nextData || managerId !== nextData.managerId,
            lastOfWorker: !nextData || workerId !== nextData.workerId,
          });
        }
      });
    });
    yield put(profitLossSheetDataActionCreators.gotProfitLossSheetDataByProfitLossSheetId({
      data: getProfitLossSheetDataByProfitLossSheetIdResponse.data,
      headers: {
        limit: headers['x-pagination-per-page'],
        page: headers['x-pagination-current-page'],
        scope: headers['x-scope'],
        search: headers['x-search'],
        sort: headers['x-pagination-sort'],
        total: headers['x-pagination-total-count'],
      },
      profitLossSheetId,
    }));
    yield put(profitLossSheetDataActionCreators.gotProfitLossSheetAssortedDataByProfitLossSheetId({
      assortedData,
      profitLossSheetId,
    }));
    yield put(profitLossSheetDataActionCreators.gotProfitLossSheetDataForUpdateStructureByProfitLossSheetId({
      profitLossSheetDataForUpdateStructure,
      profitLossSheetId,
    }));
    yield put(profitLossSheetDataActionCreators.incrementGetProfitLossSheetDataForUpdateByProfitLossSheetIdDraw({
      profitLossSheetId,
      response: getProfitLossSheetDataByProfitLossSheetIdResponse.message,
    }));
    yield put(profitLossSheetDataActionCreators.gettingProfitLossSheetDataForUpdateByProfitLossSheetId({
      loading: false,
      profitLossSheetId,
    }));
  } catch (error) {
    yield put(profitLossSheetDataActionCreators.decrementGetProfitLossSheetDataForUpdateByProfitLossSheetIdDraw({
      profitLossSheetId,
      response: error.message,
    }));
    yield put(profitLossSheetDataActionCreators.gettingProfitLossSheetDataForUpdateByProfitLossSheetId({
      loading: false,
      profitLossSheetId,
    }));
  }
};
const requestUpdateProfitLossSheetDataByProfitLossSheetId = function* ({ payload }) {
  const { params } = payload;
  const { profitLossSheetId } = params;
  try {
    yield put(profitLossSheetDataActionCreators.updatingProfitLossSheetDataByProfitLossSheetId({
      loading: true,
      profitLossSheetId,
    }));
    const { response: updateProfitLossSheetDataByProfitLossSheetIdResponse } = yield call(serviceMap['request-update-profit-loss-sheet-data-by-profit-loss-sheet-id'], payload);
    yield put(profitLossSheetDataActionCreators.updatedProfitLossSheetDataByProfitLossSheetId({ profitLossSheetId }));
    yield put(profitLossSheetDataActionCreators.incrementUpdateProfitLossSheetDataByProfitLossSheetIdDraw({
      profitLossSheetId,
      response: updateProfitLossSheetDataByProfitLossSheetIdResponse.message,
    }));
    yield put(profitLossSheetDataActionCreators.updatingProfitLossSheetDataByProfitLossSheetId({
      loading: false,
      profitLossSheetId,
    }));
  } catch (error) {
    yield put(profitLossSheetDataActionCreators.decrementUpdateProfitLossSheetDataByProfitLossSheetIdDraw({
      profitLossSheetId,
      response: error.message,
    }));
    yield put(profitLossSheetDataActionCreators.updatingProfitLossSheetDataByProfitLossSheetId({
      loading: false,
      profitLossSheetId,
    }));
  }
};
const watchRequestGetProfitLossSheetDataForUpdateByProfitLossSheetId = function* () {
  yield takeEvery(profitLossSheetDataActionTypes.GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID, requestGetProfitLossSheetDataForUpdateByProfitLossSheetId);
};
const watchRequestUpdateProfitLossSheetDataByProfitLossSheetId = function* () {
  yield takeEvery(profitLossSheetDataActionTypes.UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID, requestUpdateProfitLossSheetDataByProfitLossSheetId);
};
const sagaWatchers = {
  watchRequestGetProfitLossSheetDataForUpdateByProfitLossSheetId,
  watchRequestUpdateProfitLossSheetDataByProfitLossSheetId,
};
export default sagaWatchers;
