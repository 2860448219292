/**
 * Node modules
 */
import kebabCase from 'lodash.kebabcase';

/**
 * Endpoints
 */
import endpointMap from '../endpoints';

/**
 * Utilities
 */
import request from '../utilities/request';

const serviceMap = {};
Object.keys(endpointMap).forEach((endpointKey) => {
  const endpoint = endpointMap[endpointKey];
  const {
    host,
    port,
    protocol,
  } = endpoint;
  Object.keys(endpoint.chainMap).forEach((chainKey) => {
    const chains = endpoint.chainMap[chainKey];
    chains.forEach((chain) => {
      const { _operation, api, httpVerb } = chain;
      serviceMap[`request-${kebabCase(_operation)}`] = input => request(`${protocol}://${host}:${port}/api${api}`, {
        ...input,
        method: httpVerb.toUpperCase(),
      });
    });
  });
});
export default serviceMap;
