const getManualRecordsScope = [
  'MANUAL_RECORDS_REMOVED',
];
const chains = [];
chains.push({ _operation: 'approveManualRecordById', api: '/manual-record/:objectId/approve', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'createManualRecord', api: '/manual-record', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getManualRecords', api: '/manual-records', defaultData: [], httpVerb: 'get', scopes: getManualRecordsScope });
chains.push({ _operation: 'rejectManualRecordById', api: '/manual-record/:objectId/reject', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeManualRecordById', api: '/manual-record/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'reviewManualRecordById', api: '/manual-record/:objectId/review', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateManualRecordById', api: '/manual-record/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
