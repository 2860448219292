/**
 * Node modules
 */
import { all, fork } from 'redux-saga/effects';

/**
 * Redux internal
 */
import { saga } from './_internal';

export default function* rootSaga() {
  yield all([
    fork(saga),
  ]);
}
