/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';
import memoize from 'lodash.memoize';
import moment from 'moment';

const profitLossSheetForInputsBySaleTypeProcessedSelector = createSelector(
  state => state.reducers.profitLossSheetsForInput,
  profitLossSheetsForInput => memoize(
    (saleType) => {
      const filteredProfitLossSheetsForInput = profitLossSheetsForInput.data.filter(input => input.saleType === saleType);
      return filteredProfitLossSheetsForInput.reduce((accumulator, input) => {
        const { dateTime } = input;
        const dateKey = moment(new Date(dateTime)).format('YYYY-MM-DD');
        accumulator[dateKey] = input;
        return accumulator;
      }, {});
    },
  ),
);
const profitLossSheetForPaymentOutRecordInputsBySaleTypeProcessedSelector = createSelector(
  state => state.reducers.profitLossSheetsForPaymentOutRecordInput,
  profitLossSheetsForPaymentOutRecordInput => memoize(
    (saleType) => {
      const filteredProfitLossSheets = profitLossSheetsForPaymentOutRecordInput.data.filter(input => input.saleType === saleType);
      return filteredProfitLossSheets.reduce((accumulator, input) => {
        const { dateTime } = input;
        const dateKey = moment(new Date(dateTime)).format('YYYY-MM-DD');
        accumulator[dateKey] = input;
        return accumulator;
      }, {});
    },
  ),
);
const profitLossSheetsBySaleTypeProcessedSelector = createSelector(
  state => state.reducers.profitLossSheets,
  profitLossSheets => memoize(
    (saleType) => {
      const filteredProfitLossSheets = profitLossSheets.data.filter(input => input.saleType === saleType);
      return filteredProfitLossSheets.reduce((accumulator, input) => {
        const { dateTime } = input;
        const dateKey = moment(new Date(dateTime)).format('YYYY-MM-DD');
        accumulator[dateKey] = input;
        return accumulator;
      }, {});
    },
  ),
);
const profitLossSheetsRemovedBySaleTypeProcessedSelector = createSelector(
  state => state.reducers.profitLossSheetsRemoved,
  profitLossSheetsRemoved => memoize(
    (saleType) => {
      const filteredProfitLossSheets = profitLossSheetsRemoved.data.filter(input => input.saleType === saleType);
      return filteredProfitLossSheets.reduce((accumulator, input) => {
        const { dateTime } = input;
        const dateKey = moment(new Date(dateTime)).format('YYYY-MM-DD');
        accumulator[dateKey] = input;
        return accumulator;
      }, {});
    },
  ),
);
export default {
  profitLossSheetForInputsBySaleTypeProcessedSelector,
  profitLossSheetForPaymentOutRecordInputsBySaleTypeProcessedSelector,
  profitLossSheetsBySaleTypeProcessedSelector,
  profitLossSheetsRemovedBySaleTypeProcessedSelector,
};
