/**
 * Helpers
 */
import { createActionCreator } from '../../helpers';

const actionTypes = {
  APPROVING_PAYMENT_OUT_RECORD_BY_ID: 'APPROVING_PAYMENT_OUT_RECORD_BY_ID',
  DECREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW: 'DECREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW',
  DECREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW: 'DECREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW',
  INCREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW: 'INCREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW',
  INCREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW: 'INCREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW',
  REJECTING_PAYMENT_OUT_RECORD_BY_ID: 'REJECTING_PAYMENT_OUT_RECORD_BY_ID',
};
const actionCreators = {
  approvingPaymentOutRecordById: createActionCreator(actionTypes.APPROVING_PAYMENT_OUT_RECORD_BY_ID),
  decrementApprovePaymentOutRecordByIdDraw: createActionCreator(actionTypes.DECREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW),
  decrementRejectPaymentOutRecordByIdDraw: createActionCreator(actionTypes.DECREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW),
  incrementApprovePaymentOutRecordByIdDraw: createActionCreator(actionTypes.INCREMENT_APPROVE_PAYMENT_OUT_RECORD_BY_ID_DRAW),
  incrementRejectPaymentOutRecordByIdDraw: createActionCreator(actionTypes.INCREMENT_REJECT_PAYMENT_OUT_RECORD_BY_ID_DRAW),
  rejectingPaymentOutRecordById: createActionCreator(actionTypes.REJECTING_PAYMENT_OUT_RECORD_BY_ID),
};
export {
  actionCreators,
  actionTypes,
};
