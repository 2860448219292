/**
 * Node modules
 */
import styled from 'styled-components';

/**
 * Components
 */
import Icon from '../AntDesign/Icon';

const OverlayContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  .anticon {
    align-items: center;
    color: rgba(65, 132, 243);
    display: flex;
    flex-direction: column;
    font-size: 50px;
    font-weight: 900;
    justify-content: center;
    text-align: center;
  }
`;
const OverlayIcon = styled(Icon)``;
const OverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 66, 82);
`;
export {
  OverlayContent,
  OverlayIcon,
  OverlayWrapper,
};
