const getUsersScopes = [
  'USERS_DEACTIVATED',
  'USERS_FOR_EXTERNAL_PARTY_RELATIONSHIP_WORKER_INPUT',
  'USERS_FOR_PAYMENT_SYSTEM_RELATIONSHIP_WORKER_INPUT',
  'USERS_FOR_USER_RELATIONSHIP_MANAGER_INPUT',
  'USERS_FOR_USER_RELATIONSHIP_WORKER_INPUT',
  'USERS_REMOVED',
];
const chains = [];
chains.push({ _operation: 'activateUserById', api: '/user/:objectId/activate', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'createUser', api: '/user', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'deactivateUserById', api: '/user/:objectId/deactivate', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'getUserByUsername', api: '/user/:username', defaultData: null, httpVerb: 'get' });
chains.push({ _operation: 'getUsers', api: '/users', defaultData: [], httpVerb: 'get', scopes: getUsersScopes });
chains.push({ _operation: 'recoverUserById', api: '/user/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeUserById', api: '/user/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateUserAccountTypeById', api: '/user/:objectId/accountType', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateUserById', api: '/user/:objectId', defaultData: null, httpVerb: 'patch' });
chains.push({ _operation: 'updateUserLanguageById', api: '/user/:objectId/language', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateUserPasswordById', api: '/user/:objectId/password', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateUserUsernameById', api: '/user/:objectId/username', defaultData: null, httpVerb: 'put' });
export default chains;
