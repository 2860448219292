/**
 * Node modules
 */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import {
  connectRouter, routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import firebase from 'firebase';

/**
 * Redux actions
 */
import actionCreators from './actions';

/**
 * Redux reducers
 */
import reducers from './reducers';

/**
 * Redux sagas
 */
import rootSaga from './sagas';

/**
 * Socket
 */
import { socketEvents, socketMap } from '../socket';

/**
 * Utilities
 */
import { camelCase } from '../utilities/string';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const store = createStore(
  combineReducers({
    ...reducers,
    router: connectRouter(history),
  }),
  compose(applyMiddleware(sagaMiddleware, routeMiddleware)),
);
Object.keys(socketMap).forEach((socketKey) => {
  const socket = socketMap[socketKey];
  socketEvents.forEach((event) => {
    socket.on(event, () => {
      const action = actionCreators[camelCase(`BROADCAST_SOCKET_EVENT_${event}`)]();
      store.dispatch(action);
    });
  });
});
sagaMiddleware.run(rootSaga);
const firebaseConfig = {
  apiKey: 'AIzaSyCwXbk3kDRoTw3oaDg4qEaac9Kz0oJimjU',
  authDomain: 'smsdispatcher-71cc0.firebaseapp.com',
  databaseURL: 'https://smsdispatcher-71cc0.firebaseio.com',
  projectId: 'smsdispatcher-71cc0',
  storageBucket: 'smsdispatcher-71cc0.appspot.com',
  messagingSenderId: '114902274880',
  appId: '1:114902274880:web:7c7f9d20586e6a81',
};
firebase.initializeApp(firebaseConfig);
export {
  firebase,
  history,
  store,
};
