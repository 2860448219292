const getPaymentSystemTransactionCategoriesScopes = [
  'PAYMENT_SYSTEM_TRANSACTION_CATEGORIES_FOR_INPUT',
  'PAYMENT_SYSTEM_TRANSACTION_CATEGORIES_REMOVED',
];
const chains = [];
chains.push({ _operation: 'createPaymentSystemTransactionCategory', api: '/payment-system-transaction-category', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getPaymentSystemTransactionCategories', api: '/payment-system-transaction-categories', defaultData: [], httpVerb: 'get', scopes: getPaymentSystemTransactionCategoriesScopes });
chains.push({ _operation: 'recoverPaymentSystemTransactionCategoryById', api: '/payment-system-transaction-category/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removePaymentSystemTransactionCategoryById', api: '/payment-system-transaction-category/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updatePaymentSystemTransactionCategoryById', api: '/payment-system-transaction-category/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
