/**
 * Node modules
 */
import memoize from 'lodash.memoize';

/**
 * Common
 */
import navigation from '../common/navigation';

const generatePermissibleMenus = (permissionSet) => {
  const permissibleMenus = [];
  const mainMenuChildren = navigation.children;
  mainMenuChildren.forEach((mainMenuChild) => {
    const paths = ['', mainMenuChild.path];
    const menu = {
      children: [],
      icon: mainMenuChild.icon,
      name: mainMenuChild.name,
    };
    const subMenuChildren = mainMenuChild.children;
    subMenuChildren.forEach((subMenuChild) => {
      const resolvedPath = [...paths];
      if (subMenuChild.path) {
        resolvedPath.push(subMenuChild.path);
      }
      if (subMenuChild.renderMenu !== false && permissionSet.has(subMenuChild.permission)) {
        menu.children.push({
          name: subMenuChild.name,
          path: resolvedPath.join('/'),
        });
      }
    });
    if (menu.children.length > 0) {
      permissibleMenus.push(menu);
    }
  });
  return permissibleMenus;
};
const generatePermissibleRoutes = (permissionSet) => {
  const routes = [];
  const stack = [];
  stack.push({
    node: navigation,
    paths: [''],
    permission: navigation.permission,
    render: navigation.render,
  });
  while (stack.length > 0) {
    const {
      node,
      paths,
      permission,
      render,
    } = stack.pop();
    if (render) {
      const resolvedPath = paths.length === 1 ? '/' : paths.join('/');
      routes.push({
        path: resolvedPath,
        permission,
        render,
      });
    }
    if (node.children) {
      node.children.forEach((child) => {
        const pathList = [...paths];
        if (child.path) {
          pathList.push(child.path);
        }
        stack.push({
          node: child,
          paths: pathList,
          permission: child.permission,
          render: child.render,
        });
      });
    }
  }
  return routes.filter(route => !route.permission || permissionSet.has(route.permission));
};
const generateBreadcrumbsMap = () => {
  const breadcrumbsMap = {};
  const stack = [];
  stack.push({
    breadcrumbs: [],
    node: navigation,
    paths: [''],
  });
  while (stack.length > 0) {
    const {
      breadcrumbs,
      node,
      paths,
    } = stack.pop();
    const resolvedPath = paths.length === 1 ? '/' : paths.join('/');
    const breadcrumb = {
      clickable: node.render !== undefined,
      icon: node.icon || null,
      link: resolvedPath,
      names: [node.name],
      valid: true,
    };
    breadcrumbs.push(breadcrumb);
    if (node.render) {
      breadcrumbsMap[resolvedPath] = breadcrumbs;
    }
    if (node.children) {
      node.children.forEach((child) => {
        const pathList = [...paths];
        if (child.path) {
          pathList.push(child.path);
        }
        stack.push({
          breadcrumbs: [...breadcrumbs],
          node: child,
          paths: pathList,
        });
      });
    }
  }
  Object.keys(breadcrumbsMap).forEach((key) => {
    const breadcrumbs = breadcrumbsMap[key];
    const clonedBreadcrumbs = breadcrumbs.map(breadcrumb => ({ ...breadcrumb }));
    clonedBreadcrumbs.forEach((breadcrumb, i) => {
      const current = clonedBreadcrumbs[i];
      const previous = i > 0 && clonedBreadcrumbs[i - 1];
      if (previous && previous.link === current.link) {
        current.names = [...previous.names, ...current.names];
        previous.valid = false;
      }
      if (i === breadcrumbs.length - 1) {
        current.clickable = false;
      }
    });
    const processed = [];
    clonedBreadcrumbs.forEach((breadcrumb) => {
      if (breadcrumb.valid) {
        processed.push({
          clickable: breadcrumb.clickable,
          icon: breadcrumb.icon,
          link: breadcrumb.link,
          names: breadcrumb.names,
        });
      }
    });
    breadcrumbsMap[key] = processed;
  });
  return breadcrumbsMap;
};
const resolvedMessages = (input) => {
  const {
    currentAppLocale,
    serverAppLocale,
  } = input;
  const translationIds = new Set([...Object.keys(currentAppLocale.messages), ...Object.keys(serverAppLocale.messages)]);
  const messages = {};
  translationIds.forEach((translationId) => {
    messages[translationId] = serverAppLocale.messages[translationId] || currentAppLocale.messages[translationId];
  });
  return messages;
};
export default {
  generateBreadcrumbsMap: memoize(generateBreadcrumbsMap),
  generatePermissibleMenus: memoize(generatePermissibleMenus),
  generatePermissibleRoutes: memoize(generatePermissibleRoutes),
  resolvedMessages: memoize(resolvedMessages),
};
