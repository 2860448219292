/**
 * Node modules
 */
import { createSelector } from 'reselect/es';
import memoize from 'lodash.memoize';

/**
 * Utilities
 */
import utilities from '../../../../utilities/general';

const profitLossSheetDataForUpdateStructureByProfitLossSheetIdSelector = createSelector(
  state => state.reducers.profitLossSheetDataForUpdateStructureByProfitLossSheetId,
  profitLossSheetDataForUpdateStructureByProfitLossSheetId => memoize(
    profitLossSheetId => (profitLossSheetId && profitLossSheetDataForUpdateStructureByProfitLossSheetId[profitLossSheetId]) || {
      customerPaper: {
        managerIdMap: new Map(),
      },
      customerWebsite: {
        managerIdMap: new Map(),
      },
      partnerPaper: {
        managerIdMap: new Map(),
      },
      partnerWebsite: {
        managerIdMap: new Map(),
      },
    },
  ),
);
const profitLossSheetDataForUpdateFormByProfitLossSheetIdSelector = createSelector(
  state => state.reducers.profitLossSheetDataForUpdateFormByProfitLossSheetId,
  profitLossSheetDataForUpdateFormByProfitLossSheetId => memoize(
    profitLossSheetId => (profitLossSheetId && profitLossSheetDataForUpdateFormByProfitLossSheetId[profitLossSheetId]) || {
      changedDataMap: {},
      changeTracker: 0,
      dataMap: {},
      initialDataMap: {},
    },
  ),
);
const profitLossSheetDatumInputsProcessedByProfitLossSheetIdSelector = createSelector(
  state => state.reducers.profitLossSheetDataForInputByProfitLossSheetId,
  profitLossSheetDataForInputByProfitLossSheetId => memoize(
    (profitLossSheetId) => {
      const profitLossSheetDatumInputs = (profitLossSheetId && profitLossSheetDataForInputByProfitLossSheetId[profitLossSheetId]) || {
        data: [],
        headers: {},
      };
      return profitLossSheetDatumInputs.data.map((datum) => {
        const isCustomer = utilities.getIsCustomer(datum);
        const isPaper = utilities.getIsPaper(datum);
        const isPartner = utilities.getIsPartner(datum);
        const isWebsite = utilities.getIsWebsite(datum);
        return {
          ...datum,
          isCustomer,
          isPaper,
          isPartner,
          isWebsite,
          logoName: utilities.getLogoName({
            isCustomer,
            isPartner,
          }),
        };
      });
    },
  ),
);
export default {
  profitLossSheetDataForUpdateFormByProfitLossSheetIdSelector,
  profitLossSheetDataForUpdateStructureByProfitLossSheetIdSelector,
  profitLossSheetDatumInputsProcessedByProfitLossSheetIdSelector,
};
