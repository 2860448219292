const hasDataChanged = (props, previousProps) => Object.keys(props).filter(key => key.endsWith('BroadcastSocketEvent')).some(key => props[key] > previousProps[key]);
const getIsCustomer = (datum) => {
  const {
    isCustomerPaper,
    isCustomerWebsite,
    isMalayCustomerPaper,
    isMalayCustomerWebsite,
    isGrandDragonCustomerPaper,
    isGrandDragonCustomerWebsite,
    isThaiCustomerPaper,
    isThaiCustomerWebsite,
    isHanoiCustomerPaper,
    isHanoiCustomerWebsite,
    isLaoCustomerPaper,
    isLaoCustomerWebsite,



    isG1CustomerPaper,
    isG1CustomerWebsite,
    isG2CustomerPaper,
    isG2CustomerWebsite,
    isG3CustomerPaper,
    isG3CustomerWebsite,
    isG4CustomerPaper,
    isG4CustomerWebsite,
    isG5CustomerPaper,
    isG5CustomerWebsite,
    isG6CustomerPaper,
    isG6CustomerWebsite,
  } = datum;
  const criteria = [
    isCustomerPaper,
    isCustomerWebsite,
    isMalayCustomerPaper,
    isMalayCustomerWebsite,
    isGrandDragonCustomerPaper,
    isGrandDragonCustomerWebsite,
    isThaiCustomerPaper,
    isThaiCustomerWebsite,
    isHanoiCustomerPaper,
    isHanoiCustomerWebsite,
    isLaoCustomerPaper,
    isLaoCustomerWebsite,
    isG1CustomerPaper,
    isG1CustomerWebsite,
    isG2CustomerPaper,
    isG2CustomerWebsite,
    isG3CustomerPaper,
    isG3CustomerWebsite,
    isG4CustomerPaper,
    isG4CustomerWebsite,
    isG5CustomerPaper,
    isG5CustomerWebsite,
    isG6CustomerPaper,
    isG6CustomerWebsite,
  ];
  return criteria.some(Boolean);
};
const getIsPartner = (datum) => {
  const {
    isMalayPartnerPaper,
    isMalayPartnerWebsite,
    isGrandDragonPartnerPaper,
    isGrandDragonPartnerWebsite,
    isPartnerPaper,
    isPartnerWebsite,
    isThaiPartnerPaper,
    isThaiPartnerWebsite,
    isHanoiPartnerPaper,
    isHanoiPartnerWebsite,
    isLaoPartnerPaper,
    isLaoPartnerWebsite,

    isG1PartnerPaper,
    isG1PartnerWebsite,
    isG2PartnerPaper,
    isG2PartnerWebsite,
    isG3PartnerPaper,
    isG3PartnerWebsite,
    isG4PartnerPaper,
    isG4PartnerWebsite,
    isG5PartnerPaper,
    isG5PartnerWebsite,
    isG6PartnerPaper,
    isG6PartnerWebsite,
  } = datum;
  const criteria = [
    isMalayPartnerPaper,
    isMalayPartnerWebsite,
    isGrandDragonPartnerPaper,
    isGrandDragonPartnerWebsite,
    isHanoiPartnerPaper,
    isHanoiPartnerWebsite,
    isLaoPartnerPaper,
    isLaoPartnerWebsite,
    isPartnerPaper,
    isPartnerWebsite,
    isThaiPartnerPaper,
    isThaiPartnerWebsite,

    isG1PartnerPaper,
    isG1PartnerWebsite,
    isG2PartnerPaper,
    isG2PartnerWebsite,
    isG3PartnerPaper,
    isG3PartnerWebsite,
    isG4PartnerPaper,
    isG4PartnerWebsite,
    isG5PartnerPaper,
    isG5PartnerWebsite,
    isG6PartnerPaper,
    isG6PartnerWebsite,
  ];
  return criteria.some(Boolean);
};
const getIsThai = (datum) => {
  const {
    isThaiCustomerPaper,
    isThaiCustomerWebsite,
    isThaiPartnerPaper,
    isThaiPartnerWebsite,
  } = datum;
  const criteria = [
    isThaiCustomerPaper,
    isThaiCustomerWebsite,
    isThaiPartnerPaper,
    isThaiPartnerWebsite,
  ];
  return criteria.some(Boolean);
};
const getIsPaper = (datum) => {
  const {
    isCustomerPaper,
    isMalayCustomerPaper,
    isMalayPartnerPaper,
    isGrandDragonCustomerPaper,
    isGrandDragonPartnerPaper,
    isPartnerPaper,
    isThaiCustomerPaper,
    isThaiPartnerPaper,
    isLaoCustomerPaper,
    isLaoPartnerPaper,
    isHanoiCustomerPaper,
    isHanoiPartnerPaper,


    isG1CustomerPaper,
    isG1PartnerPaper,
    isG2CustomerPaper,
    isG2PartnerPaper,
    isG3CustomerPaper,
    isG3PartnerPaper,
    isG4CustomerPaper,
    isG4PartnerPaper,
    isG5CustomerPaper,
    isG5PartnerPaper,
    isG6CustomerPaper,
    isG6PartnerPaper,
  } = datum;
  const criteria = [
    isG1CustomerPaper,
    isG1PartnerPaper,
    isG2CustomerPaper,
    isG2PartnerPaper,
    isG3CustomerPaper,
    isG3PartnerPaper,
    isG4CustomerPaper,
    isG4PartnerPaper,
    isG5CustomerPaper,
    isG5PartnerPaper,
    isG6CustomerPaper,
    isG6PartnerPaper,
    isCustomerPaper,
    isMalayCustomerPaper,
    isMalayPartnerPaper,
    isGrandDragonCustomerPaper,
    isGrandDragonPartnerPaper,
    isPartnerPaper,
    isThaiCustomerPaper,
    isThaiPartnerPaper,
    isLaoCustomerPaper,
    isLaoPartnerPaper,
    isHanoiCustomerPaper,
    isHanoiPartnerPaper,



  ];
  return criteria.some(Boolean);
};
const getIsWebsite = (datum) => {
  const {
    isCustomerWebsite,
    isMalayCustomerWebsite,
    isMalayPartnerWebsite,
    isGrandDragonCustomerWebsite,
    isGrandDragonPartnerWebsite,
    isPartnerWebsite,
    isThaiCustomerWebsite,
    isThaiPartnerWebsite,
    isHanoiCustomerWebsite,
    isHanoiPartnerWebsite,
    isLaoCustomerWebsite,
    isLaoPartnerWebsite,


    isG1CustomerWebsite,
    isG1PartnerWebsite,
    isG2CustomerWebsite,
    isG2PartnerWebsite,
    isG3CustomerWebsite,
    isG3PartnerWebsite,
    isG4CustomerWebsite,
    isG4PartnerWebsite,
    isG5CustomerWebsite,
    isG5PartnerWebsite,
    isG6CustomerWebsite,
    isG6PartnerWebsite,
  } = datum;
  const criteria = [
    isCustomerWebsite,
    isMalayCustomerWebsite,
    isMalayPartnerWebsite,
    isGrandDragonCustomerWebsite,
    isGrandDragonPartnerWebsite,
    isPartnerWebsite,
    isThaiCustomerWebsite,
    isThaiPartnerWebsite,
    isHanoiCustomerWebsite,
    isHanoiPartnerWebsite,
    isLaoCustomerWebsite,
    isLaoPartnerWebsite,

    isG1CustomerWebsite,
    isG1PartnerWebsite,
    isG2CustomerWebsite,
    isG2PartnerWebsite,
    isG3CustomerWebsite,
    isG3PartnerWebsite,
    isG4CustomerWebsite,
    isG4PartnerWebsite,
    isG5CustomerWebsite,
    isG5PartnerWebsite,
    isG6CustomerWebsite,
    isG6PartnerWebsite,

  ];
  return criteria.some(Boolean);
};
const getIsMalay = (datum) => {
  const {
    isMalayCustomerPaper,
    isMalayCustomerWebsite,
    isMalayPartnerPaper,
    isMalayPartnerWebsite,
  } = datum;
  const criteria = [
    isMalayCustomerPaper,
    isMalayCustomerWebsite,
    isMalayPartnerPaper,
    isMalayPartnerWebsite,
  ];
  return criteria.some(Boolean);
};


const getIsHanoi = (datum) => {
  const {
    isHanoiCustomerPaper,
    isHanoiCustomerWebsite,
    isHanoiPartnerPaper,
    isHanoiPartnerWebsite,
  } = datum;
  const criteria = [
    isHanoiCustomerPaper,
    isHanoiCustomerWebsite,
    isHanoiPartnerPaper,
    isHanoiPartnerWebsite,
  ];
  return criteria.some(Boolean);
};

const getIsG1 = (datum) => {
  const {
    isG1CustomerPaper,
    isG1CustomerWebsite,
    isG1PartnerPaper,
    isG1PartnerWebsite,
  } = datum;
  const criteria = [
    isG1CustomerPaper,
    isG1CustomerWebsite,
    isG1PartnerPaper,
    isG1PartnerWebsite,
  ];
  return criteria.some(Boolean);
};



const getIsG2 = (datum) => {
  const {
    isG2CustomerPaper,
    isG2CustomerWebsite,
    isG2PartnerPaper,
    isG2PartnerWebsite,
  } = datum;
  const criteria = [
    isG2CustomerPaper,
    isG2CustomerWebsite,
    isG2PartnerPaper,
    isG2PartnerWebsite,
  ];
  return criteria.some(Boolean);
};


const getIsG3 = (datum) => {
  const {
    isG3CustomerPaper,
    isG3CustomerWebsite,
    isG3PartnerPaper,
    isG3PartnerWebsite,
  } = datum;
  const criteria = [
    isG3CustomerPaper,
    isG3CustomerWebsite,
    isG3PartnerPaper,
    isG3PartnerWebsite,
  ];
  return criteria.some(Boolean);
};


const getIsG4 = (datum) => {
  const {
    isG4CustomerPaper,
    isG4CustomerWebsite,
    isG4PartnerPaper,
    isG4PartnerWebsite,
  } = datum;
  const criteria = [
    isG4CustomerPaper,
    isG4CustomerWebsite,
    isG4PartnerPaper,
    isG4PartnerWebsite,
  ];
  return criteria.some(Boolean);
};


const getIsG5 = (datum) => {
  const {
    isG5CustomerPaper,
    isG5CustomerWebsite,
    isG5PartnerPaper,
    isG5PartnerWebsite,
  } = datum;
  const criteria = [
    isG5CustomerPaper,
    isG5CustomerWebsite,
    isG5PartnerPaper,
    isG5PartnerWebsite,
  ];
  return criteria.some(Boolean);
};


const getIsG6 = (datum) => {
  const {
    isG6CustomerPaper,
    isG6CustomerWebsite,
    isG6PartnerPaper,
    isG6PartnerWebsite,
  } = datum;
  const criteria = [
    isG6CustomerPaper,
    isG6CustomerWebsite,
    isG6PartnerPaper,
    isG6PartnerWebsite,
  ];
  return criteria.some(Boolean);
};


const getIsLao = (datum) => {
  const {
    isLaoCustomerPaper,
    isLaoCustomerWebsite,
    isLaoPartnerPaper,
    isLaoPartnerWebsite,
  } = datum;
  const criteria = [
    isLaoCustomerPaper,
    isLaoCustomerWebsite,
    isLaoPartnerPaper,
    isLaoPartnerWebsite,
  ];
  return criteria.some(Boolean);
};
const getIsGrandDragon = (datum) => {
  const {
    isGrandDragonCustomerPaper,
    isGrandDragonCustomerWebsite,
    isGrandDragonPartnerPaper,
    isGrandDragonPartnerWebsite,
  } = datum;
  const criteria = [
    isGrandDragonCustomerPaper,
    isGrandDragonCustomerWebsite,
    isGrandDragonPartnerPaper,
    isGrandDragonPartnerWebsite,
  ];
  return criteria.some(Boolean);
};

const getLogoName = (property) => {
  const {
    isCustomer,
    isPartner,
  } = property;
  let logoName = 'NoneLogo';
  if (isCustomer && isPartner) {
    logoName = 'CustomerPartnerLogo';
  } else if (isCustomer) {
    logoName = 'CustomerLogo';
  } else if (isPartner) {
    logoName = 'PartnerLogo';
  }
  return logoName;
};
const generateTreeStructure = (relationships) => {
  const map = new Map();
  const managerIds = new Set();
  const workerIds = new Set();
  const externalPartyIds = new Set();
  const paymentSystemIds = new Set();
  relationships.forEach((relationship) => {
    const {
      externalPartyId,
      externalPartyNickname,
      externalPartyRelationshipId,
      externalPartyUsername,
      isExternalPartyActivated,
      isExternalPartyCustomer,
      isExternalPartyPartner,
      isManagerActivated,
      isPaymentSystemArchived,
      isWorkerActivated,
      managerId,
      managerNickname,
      managerUsername,
      paymentSystemId,
      paymentSystemIdentifier,
      paymentSystemName,
      paymentSystemOwnerName,
      paymentSystemRelationshipId,
      userRelationshipId,
      workerId,
      workerNickname,
      workerUsername,
    } = relationship;
    const sanitizedExternalPartyId = externalPartyId || 'externalPartyId';
    const sanitizedManagerId = managerId || 'managerId';
    const sanitizedWorkerId = workerId || 'workerId';
    const sanitizedPaymentSystemId = paymentSystemId || 'paymentSystemId';
    if (!map.has(sanitizedManagerId)) {
      map.set(sanitizedManagerId, {
        children: [],
        contextId: null,
        externalPartyCount: 0,
        isActivated: isManagerActivated,
        key: sanitizedManagerId,
        nickname: managerNickname,
        nodeType: 'Manager',
        paymentSystemCount: 0,
        username: managerUsername,
      });
    }
    if (!map.has(sanitizedWorkerId)) {
      map.set(sanitizedWorkerId, {
        children: [],
        contextId: userRelationshipId,
        isActivated: isWorkerActivated,
        key: sanitizedWorkerId,
        nickname: workerNickname,
        nodeType: 'Worker',
        username: workerUsername,
      });
    }
    if (!map.has(sanitizedExternalPartyId)) {
      map.set(sanitizedExternalPartyId, {
        children: [],
        contextId: externalPartyRelationshipId,
        isActivated: isExternalPartyActivated,
        isCustomer: isExternalPartyCustomer,
        isPartner: isExternalPartyPartner,
        key: sanitizedExternalPartyId,
        logoName: getLogoName({
          isCustomer: isExternalPartyCustomer,
          isPartner: isExternalPartyPartner,
        }),
        nickname: externalPartyNickname,
        nodeType: 'External Party',
        username: externalPartyUsername,
      });
    }
    if (!map.has(sanitizedPaymentSystemId)) {
      map.set(sanitizedPaymentSystemId, {
        children: [],
        contextId: paymentSystemRelationshipId,
        identifier: paymentSystemIdentifier,
        isArchived: isPaymentSystemArchived,
        key: sanitizedPaymentSystemId,
        name: paymentSystemName,
        nodeType: 'Payment System',
        ownerName: paymentSystemOwnerName,
      });
    }
  });
  relationships.forEach((relationship) => {
    const {
      externalPartyId,
      managerId,
      paymentSystemId,
      workerId,
    } = relationship;
    const sanitizedExternalPartyId = externalPartyId || 'externalPartyId';
    const sanitizedManagerId = managerId || 'managerId';
    const sanitizedWorkerId = workerId || 'workerId';
    const sanitizedPaymentSystemId = paymentSystemId || 'paymentSystemId';
    if (!externalPartyIds.has(sanitizedExternalPartyId)) {
      if (!sanitizedExternalPartyId.endsWith('Id')) {
        map.get(sanitizedManagerId).externalPartyCount += 1;
      }
      map.get(sanitizedWorkerId)
        .children.push(map.get(sanitizedExternalPartyId));
      externalPartyIds.add(sanitizedExternalPartyId);
    }
    if (!paymentSystemIds.has(sanitizedPaymentSystemId)) {
      if (!sanitizedPaymentSystemId.endsWith('Id')) {
        map.get(sanitizedManagerId).paymentSystemCount += 1;
      }
      map.get(sanitizedWorkerId)
        .children.push(map.get(sanitizedPaymentSystemId));
      paymentSystemIds.add(sanitizedPaymentSystemId);
    }
    if (!workerIds.has(sanitizedWorkerId)) {
      map.get(sanitizedManagerId)
        .children.push(map.get(sanitizedWorkerId));
      workerIds.add(sanitizedWorkerId);
    }
    if (!managerIds.has(sanitizedManagerId)) {
      managerIds.add(sanitizedManagerId);
    }
  });
  const data = [];
  [...managerIds].forEach((id) => {
    data.push(map.get(id));
  });
  return data;
};
const generatePathInformation = (root) => {
  const pathInformation = {};
  const stack = [];
  stack.push({
    component: root.component,
    icons: [root.icon],
    names: [root.name],
    node: root,
    paths: [''],
  });
  while (stack.length > 0) {
    const {
      icons,
      names,
      node,
      paths,
      render,
    } = stack.pop();
    if (render) {
      const resolvedPath = paths.join('/');
      pathInformation[resolvedPath] = {
        icons,
        names,
        render,
      };
    }
    if (node.children) {
      node.children.forEach((child) => {
        const iconList = [...icons];
        const nameList = [...names];
        const pathList = [...paths];
        if (child.icon) {
          iconList.push(child.icon);
        }
        if (child.name) {
          nameList.push(child.name);
        }
        if (child.path) {
          pathList.push(child.path);
        }
        stack.push({
          icons: iconList,
          names: nameList,
          node: child,
          paths: pathList,
          render: child.render,
        });
      });
    }
  }
  return pathInformation;
};
export default {
  generatePathInformation,
  generateTreeStructure,
  getIsCustomer,
  getIsMalay,
  getIsPaper,
  getIsPartner,
  getIsThai,
  getIsWebsite,
  getLogoName,
  getIsGrandDragon,
  getIsHanoi,
  getIsLao,
  getIsG1,
  getIsG2,
  getIsG4,
  getIsG3,
  getIsG5,
  getIsG6,
  hasDataChanged,
};
