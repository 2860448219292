/**
 * Node modules
 */
import antdLocale from 'antd/lib/locale-provider/en_US';
import appLocaleData from 'react-intl/locale-data/en';

/**
 * Providers
 */
import messages from '../locales/en-US.json';

const locale = {
  antd: antdLocale,
  appLocaleData,
  locale: 'en-US',
  messages: {
    ...messages,
  },
};
export default locale;
