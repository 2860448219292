/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';
import memoize from 'lodash.memoize';

/**
 * Utilities
 */
import utilities from '../../../../utilities/general';

const userRelationshipTreeStructureByUserIdProcessedSelector = createSelector(
  state => state.reducers.userRelationships,
  userRelationships => memoize(
    userId => utilities.generateTreeStructure(userRelationships.data.filter(userRelationship => userRelationship.managerId === userId || userRelationship.workerId === userId)),
  ),
);
const userRelationshipTreeStructureProcessedSelector = createSelector(
  state => state.reducers.userRelationships,
  userRelationships => utilities.generateTreeStructure(userRelationships.data),
);
export default {
  userRelationshipTreeStructureByUserIdProcessedSelector,
  userRelationshipTreeStructureProcessedSelector,
};
