const getExternalPartyPhoneNumbersScopes = [
  'EXTERNAL_PARTY_PHONE_NUMBERS_REMOVED',
];
const chains = [];
chains.push({ _operation: 'createExternalPartyPhoneNumber', api: '/external-party/:externalPartyId/phone-number', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getExternalPartyPhoneNumbersByExternalPartyId', api: '/external-party/:externalPartyId/phone-numbers', defaultData: [], httpVerb: 'get', scopes: getExternalPartyPhoneNumbersScopes });
chains.push({ _operation: 'recoverExternalPartyPhoneNumberById', api: '/external-party/:externalPartyId/phone-number/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeExternalPartyPhoneNumberById', api: '/external-party/:externalPartyId/phone-number/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateExternalPartyPhoneNumberById', api: '/external-party/:externalPartyId/phone-number/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
