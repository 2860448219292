const getExternalPartyPaymentSystemsByExternalPartyIdScopes = [
  'EXTERNAL_PARTY_PAYMENT_SYSTEMS_FOR_INPUT_BY_EXTERNAL_PARTY_ID',
  'EXTERNAL_PARTY_PAYMENT_SYSTEMS_REMOVED_BY_EXTERNAL_PARTY_ID',
];
const chains = [];
chains.push({ _operation: 'createExternalPartyPaymentSystem', api: '/external-party/:externalPartyId/payment-system', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getExternalPartyPaymentSystemsByExternalPartyId', api: '/external-party/:externalPartyId/payment-systems', defaultData: [], httpVerb: 'get', scopes: getExternalPartyPaymentSystemsByExternalPartyIdScopes });
chains.push({ _operation: 'primarizeExternalPartyPaymentSystemById', api: '/external-party/:externalPartyId/payment-system/:objectId/primarize', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'recoverExternalPartyPaymentSystemById', api: '/external-party/:externalPartyId/payment-system/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeExternalPartyPaymentSystemById', api: '/external-party/:externalPartyId/payment-system/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateExternalPartyPaymentSystemById', api: '/external-party/:externalPartyId/payment-system/:objectId', defaultData: null, httpVerb: 'patch' });
chains.push({ _operation: 'updateExternalPartyPaymentSystemIdentifierById', api: '/external-party/:externalPartyId/payment-system/:objectId/identifier', defaultData: null, httpVerb: 'patch' });
export default chains;
