/**
 * Node modules
 */
import axios from 'axios';
import pathToRegex from 'path-to-regexp';

export default async function request(url, input) {
  const {
    body,
    method,
    params,
    query,
  } = input;
  const token = localStorage.getItem('token');
  const axiosInput = {
    headers: {
      'Accept': 'application/json',
      'Authorization': token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json; charset=utf-8',
    },
    method,
    url,
    withCredentials: true,
  };
  if (params) {
    const link = new URL(url);
    link.pathname = pathToRegex.compile(link.pathname)(params);
    axiosInput.url = link.href;
  }
  if (method === 'GET') {
    axiosInput.params = query;
  }
  if (method === 'PATCH' || method === 'POST' || method === 'PUT' || method === 'DELETE') {
    axiosInput.data = body;
  }
  const {
    data: axiosData,
    headers,
  } = await axios(axiosInput);
  if (axiosData.status === 'error') {
    throw new Error(axiosData.message);
  } else if (axiosData.status === 'success') {
    return {
      headers,
      response: axiosData,
    };
  } else {
    throw new Error('...');
  }
}
