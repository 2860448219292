const getPaymentInRecordsScopes = [
  'PAYMENT_IN_RECORDS_CLAIMED',
  'PAYMENT_IN_RECORDS_RECENT',
  'PAYMENT_IN_RECORDS_REMOVED',
  'PAYMENT_IN_RECORDS_UNCLAIMED',
];
const chains = [];
chains.push({ _operation: 'claimPaymentInRecordById', api: '/payment-in-record/:objectId/claim', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'createPaymentInRecord', api: '/payment-in-record', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getPaymentInRecords', api: '/payment-in-records', defaultData: [], httpVerb: 'get', scopes: getPaymentInRecordsScopes });
chains.push({ _operation: 'removePaymentInRecordById', api: '/payment-in-record/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'unclaimPaymentInRecordById', api: '/payment-in-record/:objectId/unclaim', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updatePaymentInRecordById', api: '/payment-in-record/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
