/**
 * Node modules
 */
import merge from 'lodash.merge';

/**
 * Override
 */
import paymentOutRecordDetailGroupSagaWatchers from './payment-out-record-detail-group/saga';
import profitLossSheetDatumSagaWatchers from './profit-loss-sheet-datum/saga';
import profitLossSheetSagaWatchers from './profit-loss-sheet/saga';
import userAuthenticationSagaWatchers from './user-authentication/saga';
import userPermissionSagaWatchers from './user-permission/saga';

/**
 * Redux actions
 */
import { actionCreators as appActionCreators } from './app/actions';
import { actionCreators as paymentOutRecordActionCreators } from './payment-out-record/actions';
import {
  actionCreators as paymentOutRecordDetailGroupActionCreators,
} from './payment-out-record-detail-group/actions';
import {
  actionCreators as profitLossSheetActionCreators,
} from './profit-loss-sheet/actions';
import {
  actionCreators as profitLossSheetDatumActionCreators,
} from './profit-loss-sheet-datum/actions';
import {
  actionCreators as userAuthenticationActionCreators,
} from './user-authentication/actions';
import {
  actionCreators as userPermissionActionCreators,
} from './user-permission/actions';

/**
 * Redux reducers
 */
import {
  handlers as appHandlers,
  initialState as appState,
} from './app/reducers';
import {
  handlers as paymentOutRecordHandlers,
  initialState as paymentOutRecordState,
} from './payment-out-record/reducers';
import {
  handlers as profitLossSheetDatumHandlers,
  initialState as profitLossSheetDatumState,
} from './profit-loss-sheet-datum/reducers';
import {
  handlers as userAuthenticationHandlers,
  initialState as userAuthenticationState,
} from './user-authentication/reducers';
import {
  handlers as userPermissionHandlers,
  initialState as userPermissionState,
} from './user-permission/reducers';

/**
 * Redux selectors
 */
import appSelectors from './app/selectors';
import externalPartyRelationshipSelectors from './external-party-relationship/selectors';
import externalPartySelectors from './external-party/selectors';
import paymentInRecordSelectors from './payment-in-record/selectors';
import paymentOutRecordSelectors from './payment-out-record/selectors';
import paymentSystemRelationshipSelectors from './payment-system-relationship/selectors';
import profitLossSheetDatumSelectors from './profit-loss-sheet-datum/selectors';
import profitLossSheetSelectors from './profit-loss-sheet/selectors';
import userAuthenticationSelectors from './user-authentication/selectors';
import userPermissionSelectors from './user-permission/selectors';
import userRelationshipSelectors from './user-relationship/selectors';

const actionCreators = {
  ...appActionCreators,
  ...paymentOutRecordActionCreators,
  ...paymentOutRecordDetailGroupActionCreators,
  ...profitLossSheetActionCreators,
  ...profitLossSheetDatumActionCreators,
  ...userAuthenticationActionCreators,
  ...userPermissionActionCreators,
};
const handlers = {
  ...appHandlers,
  ...paymentOutRecordHandlers,
  ...profitLossSheetDatumHandlers,
  ...userAuthenticationHandlers,
  ...userPermissionHandlers,
};
const sagaWatchers = {
  ...paymentOutRecordDetailGroupSagaWatchers,
  ...profitLossSheetDatumSagaWatchers,
  ...profitLossSheetSagaWatchers,
  ...userAuthenticationSagaWatchers,
  ...userPermissionSagaWatchers,
};
const selectors = {
  ...appSelectors,
  ...externalPartyRelationshipSelectors,
  ...externalPartySelectors,
  ...paymentInRecordSelectors,
  ...paymentOutRecordSelectors,
  ...paymentSystemRelationshipSelectors,
  ...profitLossSheetDatumSelectors,
  ...profitLossSheetSelectors,
  ...userAuthenticationSelectors,
  ...userPermissionSelectors,
  ...userRelationshipSelectors,
};
const state = merge(
  {},
  { ...appState },
  { ...userAuthenticationState },
  { ...paymentOutRecordState },
  { ...profitLossSheetDatumState },
  { ...userPermissionState },
);
export {
  actionCreators,
  handlers,
  sagaWatchers,
  selectors,
  state,
};
