const CURRENCY_MAP = {
  MYR: 'RM',
  THB: '฿',
};
const CURRENCY_RATE_THAI_BASE = {
  MYR: 7.96,
  THB: 1,
};
const DEFAULT_SORT = 'createdTimestamp:desc';
const FLAG_MAP = {
  MALAY: 'MY',
  THAI: 'TH',
};
const OTHER_BANK = {
  identifier: '',
  name: 'OTHERS',
  objectId: null,
  ownerName: 'Cull A.',
};
const PAYMENT_SYSTEM_GROUP = {
  'title.label.bank': [
    'BAAC',
    'BBL',
    'BNP',
    'BOA',
    'BTMU',
    'CA',
    'CHASE',
    'CIMB',
    'CITI',
    'DBK',
    'GH',
    'GSB',
    'HSBC',
    'IBANK',
    'ICBC',
    'KBANK',
    'KIATNAKIN',
    'KRUNGSRI',
    'KTB',
    'LH',
    'MICBC',
    'MIZUHO',
    'OTHERS',
    'RBS',
    'RETAIL',
    'SCB',
    'SMBC',
    'STAN',
    'TCHART',
    'TISCO',
    'TMB',
    'UOB',
  ],
  'title.label.cash': ['CASH'],
};
const SERVER_SIDED_NOTES = new Set([
  'note.created.by.approval.process',
  'note.created.by.system',
  'note.decreased.by.approval.process',
  'note.decreased.by.rejection.process',
  'note.edited.single.entry',
  'note.incorrect.password',
  'note.increased.by.reversion.process',
  'note.locked.by.printing',
  'note.primarized.by.system',
  'note.removed.by.refreshing',
  'note.unassigned.by.system',
  'note.user.is.not.activated',
]);
const STATUS_COLOR_MAP = {
  APPROVED: 'green',
  CLAIMED: 'green',
  COMPLETED: 'green',
  IN_PROGRESS: 'blue',
  PENDING: 'gold',
  REJECTED: 'red',
  UNCLAIMED: 'gold',
};
const UUID_0 = '00000000-0000-0000-0000-000000000000';
export {
  CURRENCY_MAP,
  CURRENCY_RATE_THAI_BASE,
  DEFAULT_SORT,
  FLAG_MAP,
  OTHER_BANK,
  PAYMENT_SYSTEM_GROUP,
  SERVER_SIDED_NOTES,
  STATUS_COLOR_MAP,
  UUID_0,
};
