/**
 * Node modules
 */
import { createSelector } from 'reselect/lib/index';
import memoize from 'lodash.memoize';

const currentUserPermissionsSelector = state => state.reducers.currentUserPermissions;
const getCurrentUserPermissionsRequestSelector = state => state.reducers.request.getCurrentUserPermissions;
const isGettingCurrentUserPermissionsSelector = state => state.reducers.loading.isGettingCurrentUserPermissions;
const currentUserPermissionSetSelector = createSelector(
  state => state.reducers.currentUserPermissions,
  currentUserPermissions => new Set(currentUserPermissions.data.filter(datum => datum.isActivated).map(datum => datum.permission)),
);
const userPermissionsProcessedByUserIdProcessedSelector = createSelector(
  state => state.reducers.userPermissionsByUserId,
  userPermissionsByUserId => memoize(
    userId => ((userId && userPermissionsByUserId[userId]) || {
      data: [],
    }).data.reduce((accumulator, datum) => {
      const { permission } = datum;
      const {
        get,
        page,
        process,
        remove,
        update,
      } = accumulator;
      if (permission.startsWith('PAGE')) {
        page.push(datum);
      } else if (permission.startsWith('GET')) {
        get.push(datum);
      } else if (permission.startsWith('UPDATE')) {
        update.push(datum);
      } else if (permission.startsWith('REMOVE') || permission.startsWith('DELETE')) {
        remove.push(datum);
      } else {
        process.push(datum);
      }
      return accumulator;
    }, {
      get: [],
      page: [],
      process: [],
      remove: [],
      update: [],
    }),
  ),
);
export default {
  currentUserPermissionSetSelector,
  currentUserPermissionsSelector,
  getCurrentUserPermissionsRequestSelector,
  isGettingCurrentUserPermissionsSelector,
  userPermissionsProcessedByUserIdProcessedSelector,
};
