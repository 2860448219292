/**
 * Helpers
 */
import { createActionCreator } from '../../helpers';

const actionTypes = {
  CHANGE_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_FORM_BY_PROFIT_LOSS_SHEET_ID: 'CHANGE_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_FORM_BY_PROFIT_LOSS_SHEET_ID',
  DECREMENT_GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID_DRAW: 'DECREMENT_GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID_DRAW',
  DECREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW: 'DECREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW',
  GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID: 'GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID',
  GETTING_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID: 'GETTING_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID',
  GOT_PROFIT_LOSS_SHEET_ASSORTED_DATA_BY_PROFIT_LOSS_SHEET_ID: 'GOT_PROFIT_LOSS_SHEET_ASSORTED_DATA_BY_PROFIT_LOSS_SHEET_ID',
  GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID: 'GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID',
  GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_STRUCTURE_BY_PROFIT_LOSS_SHEET_ID: 'GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_STRUCTURE_BY_PROFIT_LOSS_SHEET_ID',
  INCREMENT_GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID_DRAW: 'INCREMENT_GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID_DRAW',
  INCREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW: 'INCREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW',
  UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID: 'UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID',
  UPDATED_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID: 'UPDATED_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID',
  UPDATING_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID: 'UPDATING_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID',
};
const actionCreators = {
  changeProfitLossSheetDataForUpdateFormByProfitLossSheetId: createActionCreator(actionTypes.CHANGE_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_FORM_BY_PROFIT_LOSS_SHEET_ID),
  decrementGetProfitLossSheetDataForUpdateByProfitLossSheetIdDraw: createActionCreator(actionTypes.DECREMENT_GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID_DRAW),
  decrementUpdateProfitLossSheetDataByProfitLossSheetIdDraw: createActionCreator(actionTypes.DECREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW),
  getProfitLossSheetDataForUpdateByProfitLossSheetId: createActionCreator(actionTypes.GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID),
  gettingProfitLossSheetDataForUpdateByProfitLossSheetId: createActionCreator(actionTypes.GETTING_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID),
  gotProfitLossSheetAssortedDataByProfitLossSheetId: createActionCreator(actionTypes.GOT_PROFIT_LOSS_SHEET_ASSORTED_DATA_BY_PROFIT_LOSS_SHEET_ID),
  gotProfitLossSheetDataByProfitLossSheetId: createActionCreator(actionTypes.GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID),
  gotProfitLossSheetDataForUpdateStructureByProfitLossSheetId: createActionCreator(actionTypes.GOT_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_STRUCTURE_BY_PROFIT_LOSS_SHEET_ID),
  incrementGetProfitLossSheetDataForUpdateByProfitLossSheetIdDraw: createActionCreator(actionTypes.INCREMENT_GET_PROFIT_LOSS_SHEET_DATA_FOR_UPDATE_BY_PROFIT_LOSS_SHEET_ID_DRAW),
  incrementUpdateProfitLossSheetDataByProfitLossSheetIdDraw: createActionCreator(actionTypes.INCREMENT_UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID_DRAW),
  updatedProfitLossSheetDataByProfitLossSheetId: createActionCreator(actionTypes.UPDATED_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID),
  updateProfitLossSheetDataByProfitLossSheetId: createActionCreator(actionTypes.UPDATE_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID),
  updatingProfitLossSheetDataByProfitLossSheetId: createActionCreator(actionTypes.UPDATING_PROFIT_LOSS_SHEET_DATA_BY_PROFIT_LOSS_SHEET_ID),
};
export {
  actionCreators,
  actionTypes,
};
