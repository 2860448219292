const getExternalPartyLineUsernamesScopes = [
  'EXTERNAL_PARTY_LINE_USERNAMES_REMOVED',
];
const chains = [];
chains.push({ _operation: 'createExternalPartyLineUsername', api: '/external-party/:externalPartyId/line-username', defaultData: null, httpVerb: 'post' });
chains.push({ _operation: 'getExternalPartyLineUsernamesByExternalPartyId', api: '/external-party/:externalPartyId/line-usernames', defaultData: [], httpVerb: 'get', scopes: getExternalPartyLineUsernamesScopes });
chains.push({ _operation: 'recoverExternalPartyLineUsernameById', api: '/external-party/:externalPartyId/line-username/:objectId/recover', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'removeExternalPartyLineUsernameById', api: '/external-party/:externalPartyId/line-username/:objectId/remove', defaultData: null, httpVerb: 'put' });
chains.push({ _operation: 'updateExternalPartyLineUsernameById', api: '/external-party/:externalPartyId/line-username/:objectId', defaultData: null, httpVerb: 'patch' });
export default chains;
