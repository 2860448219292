/**
 * Node modules
 */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect/es';
import memoize from 'lodash.memoize';
import merge from 'lodash.merge';
import snakeCase from 'lodash.snakecase';

/**
 * Endpoints
 */
import endpointMap from '../../endpoints';

/**
 * Helpers
 */
import { createActionCreator, handleActions } from './helpers';

/**
 * Override
 */
import {
  actionCreators as overriddenActionCreators,
  handlers as overriddenHandlers,
  sagaWatchers as overriddenSagaWatchers,
  selectors as overriddenSelectors,
  state as overriddenState,
} from './override';

/**
 * Services
 */
import serviceMap from '../../services';

/**
 * Socket
 */
import { socketEvents } from '../../socket';

/**
 * Utilities
 */
import { camelCase, kebabCase } from '../../utilities/string';

const getByParameter = (actionKey) => {
  const byWhat = actionKey.split('_BY_')[1];
  if (!byWhat) {
    return undefined;
  }
  return camelCase(byWhat === 'ID' ? 'OBJECT_ID' : byWhat);
};
const revertToBaseActionType = convertedAction => convertedAction
  .replace('BROADCAST_SOCKET_EVENT_', '')
  .replace('INCREMENT_', '')
  .replace('DECREMENT_', '')
  .replace('_DRAW', '')
  .replace('GOT_', 'GET_');
const flattenedChains = [];
Object.keys(endpointMap).forEach((endpointKey) => {
  const endpoint = endpointMap[endpointKey];
  Object.keys(endpoint.chainMap).forEach((chainKey) => {
    const chains = endpoint.chainMap[chainKey];
    chains.forEach((chain) => {
      flattenedChains.push(chain);
    });
  });
});
const generateActions = (chains) => {
  const broadcastSocketEventActionCreatorObject = {};
  socketEvents.forEach((socketEvent) => {
    const broadcastSocketEventActionType = `BROADCAST_SOCKET_EVENT_${snakeCase(socketEvent).toUpperCase()}`;
    broadcastSocketEventActionCreatorObject[camelCase(broadcastSocketEventActionType)] = createActionCreator(broadcastSocketEventActionType);
  });
  const clonedChains = [...chains];
  const getServiceKey = action => kebabCase(`REQUEST_${action}`);

  /**
   * ActionType key getters
   */
  const getBaseActionTypeKey = _operation => snakeCase(_operation).toUpperCase();
  const getDrawDecrementActionTypeKey = action => getBaseActionTypeKey(`DECREMENT_${action}_DRAW`);
  const getGotActionTypeKey = action => getBaseActionTypeKey(action.replace('GET_', 'GOT_'));
  const getDrawIncrementActionTypeKey = action => getBaseActionTypeKey(`INCREMENT_${action}_DRAW`);
  const getScopeBaseActionTypeKey = scope => getBaseActionTypeKey(`GET_${scope}`);
  const getLoadingActionTypeKey = (action) => {
    const chunks = action.split('_');
    const loadingVerbMap = {
      ACTIVATE: 'ACTIVATING',
      APPROVE: 'APPROVING',
      AUTHENTICATE: 'AUTHENTICATING',
      AUTHORIZE: 'AUTHORIZING',
      CLAIM: 'CLAIMING',
      CREATE: 'CREATING',
      DEACTIVATE: 'DEACTIVATING',
      DELETE: 'DELETING',
      GET: 'GETTING',
      LOCK: 'LOCKING',
      PRIMARIZE: 'PRIMARIZING',
      PRINT: 'PRINTING',
      RECOVER: 'RECOVERING',
      REFRESH: 'REFRESHING',
      REJECT: 'REJECTING',
      REMOVE: 'REMOVING',
      REVERT: 'REVERTING',
      REVIEW: 'REVIEWING',
      UNCLAIM: 'UNCLAIMING',
      UNLOCK: 'UNLOCKING',
      UPDATE: 'UPDATING',
      VERIFY: 'VERIFYING',
    };
    chunks[0] = loadingVerbMap[chunks[0]];
    return getBaseActionTypeKey(chunks.join('_'));
  };

  /**
   * Constants
   */
  const drawDecrementActionCreatorObject = {};
  const drawIncrementActionCreatorObject = {};
  const gotActionDefaultDataMap = {};
  const gotActionCreatorObject = {};
  const loadingActionCreatorObject = {};
  const sagaActionCreatorObject = {};
  const sagaActionContextObject = {};

  /**
   * Populating
   */
  clonedChains.forEach((chain) => {
    const { _operation, defaultData, httpVerb, scopes } = chain;
    const actionType = getBaseActionTypeKey(_operation);
    const serviceKey = getServiceKey(actionType);
    const drawDecrementActionType = getDrawDecrementActionTypeKey(actionType);
    const drawIncrementActionType = getDrawIncrementActionTypeKey(actionType);
    const loadingActionType = getLoadingActionTypeKey(actionType);
    drawDecrementActionCreatorObject[camelCase(drawDecrementActionType)] = createActionCreator(drawDecrementActionType);
    drawIncrementActionCreatorObject[camelCase(drawIncrementActionType)] = createActionCreator(drawIncrementActionType);
    loadingActionCreatorObject[camelCase(loadingActionType)] = createActionCreator(loadingActionType);
    sagaActionCreatorObject[camelCase(actionType)] = createActionCreator(actionType);
    if (httpVerb === 'get') {
      const gotActionType = getGotActionTypeKey(actionType);
      gotActionCreatorObject[camelCase(gotActionType)] = createActionCreator(gotActionType);
      gotActionDefaultDataMap[gotActionType] = defaultData;
      sagaActionContextObject[actionType] = {
        drawDecrementActionType,
        drawIncrementActionType,
        gotActionType,
        loadingActionType,
        serviceKey,
      };
      if (scopes) {
        scopes.forEach((scope) => {
          const scopeActionType = getScopeBaseActionTypeKey(scope);
          const scopeDrawDecrementActionType = getDrawDecrementActionTypeKey(scopeActionType);
          const scopeDrawIncrementActionType = getDrawIncrementActionTypeKey(scopeActionType);
          const scopeGotActionType = getGotActionTypeKey(scopeActionType);
          const scopeLoadingActionType = getLoadingActionTypeKey(scopeActionType);
          drawDecrementActionCreatorObject[camelCase(scopeDrawDecrementActionType)] = createActionCreator(scopeDrawDecrementActionType);
          drawIncrementActionCreatorObject[camelCase(scopeDrawIncrementActionType)] = createActionCreator(scopeDrawIncrementActionType);
          gotActionCreatorObject[camelCase(scopeGotActionType)] = createActionCreator(scopeGotActionType);
          loadingActionCreatorObject[camelCase(scopeLoadingActionType)] = createActionCreator(scopeLoadingActionType);
          sagaActionCreatorObject[camelCase(scopeActionType)] = createActionCreator(scopeActionType);
          gotActionDefaultDataMap[scopeGotActionType] = defaultData;
          sagaActionContextObject[scopeActionType] = {
            drawDecrementActionType: scopeDrawDecrementActionType,
            drawIncrementActionType: scopeDrawIncrementActionType,
            gotActionType: scopeGotActionType,
            loadingActionType: scopeLoadingActionType,
            serviceKey,
          };
        });
      }
    } else {
      sagaActionContextObject[actionType] = {
        drawDecrementActionType,
        drawIncrementActionType,
        loadingActionType,
        serviceKey,
      };
    }
  });
  return {
    broadcastSocketEventActionCreatorObject,
    drawDecrementActionCreatorObject,
    drawIncrementActionCreatorObject,
    gotActionCreatorObject,
    gotActionDefaultDataMap,
    loadingActionCreatorObject,
    sagaActionContextObject,
    sagaActionCreatorObject,
  };
};
const generateHandlersAndSelectors = (generatedActions) => {
  const {
    broadcastSocketEventActionCreatorObject,
    drawDecrementActionCreatorObject,
    drawIncrementActionCreatorObject,
    gotActionCreatorObject,
    gotActionDefaultDataMap,
    loadingActionCreatorObject,
  } = generatedActions;
  const broadcastSocketEventActions = Object.keys(broadcastSocketEventActionCreatorObject).map(key => snakeCase(key).toUpperCase());
  const drawDecrementActions = Object.keys(drawDecrementActionCreatorObject).map(key => snakeCase(key).toUpperCase());
  const drawIncrementActions = Object.keys(drawIncrementActionCreatorObject).map(key => snakeCase(key).toUpperCase());
  const gotActions = Object.keys(gotActionCreatorObject).map(key => snakeCase(key).toUpperCase());
  const loadingActions = Object.keys(loadingActionCreatorObject).map(key => snakeCase(key).toUpperCase());

  /**
   * Selector key creators
   */
  const getSelectorKey = action => camelCase(`${action}_SELECTOR`);

  /**
   * State key creators
   */
  const getBaseStateKey = action => camelCase(action);
  const getBroadcastSocketEventStateKey = action => getBaseStateKey(action);
  const getGotStateKey = action => getBaseStateKey(action);
  const getLoadingStateKey = action => getBaseStateKey(`IS_${action}`);
  const getRequestStateKey = action => getBaseStateKey(action);

  /**
   * State constructors
   */
  const constructBroadcastSocketEventState = () => 0;
  const constructGotState = (byParameter, defaultData) => (byParameter ? {} : {
    data: defaultData,
    headers: {},
  });
  const constructLoadingState = byParameter => (byParameter ? {} : false);
  const constructRequestState = byParameter => (
    byParameter ? {} : {
      draw: 0,
      response: '',
    }
  );

  /**
   * Handle action constructors
   */
  const constructBroadcastSocketEventReducer = broadcastSocketEventState => state => ({
    ...state,
    broadcastSocketEvent: {
      ...state.broadcastSocketEvent,
      [broadcastSocketEventState]: state.broadcastSocketEvent[broadcastSocketEventState] + 1,
    },
  });
  const constructBaseDrawReducer = (requestState, byParameter, sign) => (
    byParameter ? (state, { payload }) => {
      const { response } = payload;
      const by = payload[byParameter];
      const previous = state.request[requestState][by];
      return {
        ...state,
        request: {
          ...state.request,
          [requestState]: {
            ...state.request[requestState],
            [by]: {
              ...state.request[requestState][by],
              draw: !previous ? sign : previous.draw + sign,
              response,
            },
          },
        },
      };
    } : (state, { payload }) => {
      const { response } = payload;
      return {
        ...state,
        request: {
          ...state.request,
          [requestState]: {
            ...state.request[requestState],
            draw: state.request[requestState].draw + sign,
            response,
          },
        },
      };
    }
  );
  const constructDrawDecrementReducer = (requestState, byParameter) => constructBaseDrawReducer(requestState, byParameter, -1);
  const constructDrawIncrementReducer = (requestState, byParameter) => constructBaseDrawReducer(requestState, byParameter, 1);
  const constructGotReducer = (gotState, byParameter) => (
    byParameter ? (state, { payload }) => {
      const {
        data,
        headers,
      } = payload;
      const by = payload[byParameter];
      return {
        ...state,
        [gotState]: {
          ...state[gotState],
          [by]: {
            ...state[gotState][by],
            data,
            headers,
          },
        },
      };
    } : (state, { payload }) => {
      const {
        data,
        headers,
      } = payload;
      return {
        ...state,
        [gotState]: {
          ...state[gotState],
          data,
          headers,
        },
      };
    }
  );
  const constructLoadingReducer = (loadingState, byParameter) => (
    byParameter ? (state, { payload }) => {
      const { loading } = payload;
      const by = payload[byParameter];
      return {
        ...state,
        loading: {
          ...state.loading,
          [loadingState]: {
            ...state.loading[loadingState],
            [by]: loading,
          },
        },
      };
    } : (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading: {
          ...state.loading,
          [loadingState]: loading,
        },
      };
    }
  );

  /**
   * Selector constructors
   */
  const constructBroadcastSocketEventSelector = broadcastSocketEventState => state => state.reducers.broadcastSocketEvent[broadcastSocketEventState];
  const constructGotSelector = (gotState, byParameter, defaultData) => (
    byParameter ? createSelector(
      state => state.reducers[gotState],
      object => memoize(by => (by && object[by]) || {
        data: defaultData,
        headers: {},
      }),
    ) : state => state.reducers[gotState]
  );
  const constructRequestSelector = (requestState, byParameter) => (
    byParameter ? createSelector(
      state => state.reducers.request[requestState],
      object => memoize(
        by => (by && object[by]) || {
          draw: 0,
          response: '',
        },
      ),
    ) : state => state.reducers.request[requestState]
  );
  const constructLoadingSelector = (loadingState, byParameter) => (
    byParameter ? createSelector(
      state => state.reducers.loading[loadingState],
      object => memoize(by => (by && object[by]) || false),
    ) : state => state.reducers.loading[loadingState]
  );

  /**
   * Constants
   */
  const initialStateObject = {
    broadcastSocketEvent: {},
    loading: {},
    request: {},
  };
  const handlerObject = {};
  const selectorObject = {};

  /**
   * Populating
   */
  broadcastSocketEventActions.forEach((broadcastSocketEventType) => {
    const action = revertToBaseActionType(broadcastSocketEventType);
    const broadcastSocketEventState = getBroadcastSocketEventStateKey(action);
    const broadcastSelector = getSelectorKey(`${action}_BROADCAST_SOCKET_EVENT`);
    initialStateObject.broadcastSocketEvent[broadcastSocketEventState] = constructBroadcastSocketEventState();
    handlerObject[broadcastSocketEventType] = constructBroadcastSocketEventReducer(broadcastSocketEventState);
    selectorObject[broadcastSelector] = constructBroadcastSocketEventSelector(broadcastSocketEventState);
  });
  drawDecrementActions.forEach((drawDecrementActionType) => {
    const action = revertToBaseActionType(drawDecrementActionType);
    const byParameter = getByParameter(action);
    const requestState = getRequestStateKey(action);
    const requestSelector = getSelectorKey(`${action}_REQUEST`);
    initialStateObject.request[requestState] = constructRequestState(byParameter);
    handlerObject[drawDecrementActionType] = constructDrawDecrementReducer(requestState, byParameter);
    selectorObject[requestSelector] = constructRequestSelector(requestState, byParameter);
  });
  drawIncrementActions.forEach((drawIncrementActionType) => {
    const action = revertToBaseActionType(drawIncrementActionType);
    const byParameter = getByParameter(action);
    const requestState = getRequestStateKey(action);
    const requestSelector = getSelectorKey(`${action}_REQUEST`);
    initialStateObject.request[requestState] = constructRequestState(byParameter);
    handlerObject[drawIncrementActionType] = constructDrawIncrementReducer(requestState, byParameter);
    selectorObject[requestSelector] = constructRequestSelector(requestState, byParameter);
  });
  loadingActions.forEach((loadingActionType) => {
    const action = revertToBaseActionType(loadingActionType);
    const byParameter = getByParameter(action);
    const loadingState = getLoadingStateKey(action);
    const loadingSelector = getSelectorKey(`IS_${action}`);
    initialStateObject.loading[loadingState] = constructLoadingState(byParameter);
    handlerObject[loadingActionType] = constructLoadingReducer(loadingState, byParameter);
    selectorObject[loadingSelector] = constructLoadingSelector(loadingState, byParameter);
  });
  gotActions.forEach((gotActionType) => {
    const action = revertToBaseActionType(gotActionType);
    const byParameter = getByParameter(action);
    const gotState = getGotStateKey(action.replace('GET_', ''));
    const gotSelector = getSelectorKey(action.replace('GET_', ''));
    initialStateObject[gotState] = constructGotState(byParameter, gotActionDefaultDataMap[gotActionType]);
    handlerObject[gotActionType] = constructGotReducer(gotState, byParameter);
    selectorObject[gotSelector] = constructGotSelector(gotState, byParameter, gotActionDefaultDataMap[gotActionType]);
  });
  return {
    handlerObject,
    initialStateObject,
    selectorObject,
  };
};
const generateSagaWatchers = (generatedActions) => {
  const {
    drawDecrementActionCreatorObject,
    drawIncrementActionCreatorObject,
    gotActionCreatorObject,
    loadingActionCreatorObject,
    sagaActionContextObject,
  } = generatedActions;
  const getSagaWatcherKey = sagaActionType => camelCase(`WATCH_REQUEST_${sagaActionType}`);
  const getDecomposedBy = (payload, byParameter) => {
    if (byParameter) {
      const { params } = payload;
      const by = params[byParameter];
      const decomposedBys = by.split('#');
      const decomposedByParameters = byParameter.split('And');
      const constructedParams = {};
      decomposedBys.forEach((decomposedBy, index) => {
        constructedParams[camelCase(decomposedByParameters[index])] = decomposedBy;
      });
      return {
        ...payload,
        params: {
          ...payload.params,
          ...constructedParams,
        },
      };
    }
    return payload;
  };
  const constructSagaWorker = (actionCreatorObject, byParameter, serviceKey) => {
    const {
      drawDecrementActionCreator,
      drawIncrementActionCreator,
      gotActionCreator,
      loadingActionCreator,
    } = actionCreatorObject;
    let sagaWorker = null;
    if (byParameter) {
      sagaWorker = function* ({ payload }) {
        const by = payload.params[byParameter];
        try {
          yield put(loadingActionCreator({
            [byParameter]: by,
            loading: true,
          }));
          const {
            headers,
            response,
          } = yield call(serviceMap[serviceKey], getDecomposedBy(payload, byParameter));
          if (gotActionCreator) {
            const { data } = response;
            yield put(gotActionCreator({
              [byParameter]: by,
              data,
              headers: {
                limit: headers['x-pagination-per-page'],
                page: headers['x-pagination-current-page'],
                scope: headers['x-scope'],
                search: headers['x-search'],
                sort: headers['x-pagination-sort'],
                total: headers['x-pagination-total-count'],
                totalAmountMap: headers['x-records-total-amount-map'] ? JSON.parse(headers['x-records-total-amount-map']) : {},
              },
            }));
          }
          yield put(drawIncrementActionCreator({
            [byParameter]: by,
            response: response.message,
          }));
        } catch (error) {
          yield put(drawDecrementActionCreator({
            [byParameter]: by,
            response: error.message,
          }));
        } finally {
          yield put(loadingActionCreator({
            [byParameter]: by,
            loading: false,
          }));
        }
      };
    } else {
      sagaWorker = function* ({ payload }) {
        try {
          yield put(loadingActionCreator({ loading: true }));
          const {
            headers,
            response,
          } = yield call(serviceMap[serviceKey], getDecomposedBy(payload, byParameter));
          if (gotActionCreator) {
            const { data } = response;
            yield put(gotActionCreator({
              data,
              headers: {
                limit: headers['x-pagination-per-page'],
                page: headers['x-pagination-current-page'],
                scope: headers['x-scope'],
                search: headers['x-search'],
                sort: headers['x-pagination-sort'],
                total: headers['x-pagination-total-count'],
                totalAmountMap: headers['x-records-total-amount-map'] ? JSON.parse(headers['x-records-total-amount-map']) : {},
              },
            }));
          }
          yield put(drawIncrementActionCreator({ response: response.message }));
        } catch (error) {
          yield put(drawDecrementActionCreator({ response: error.message }));
        } finally {
          yield put(loadingActionCreator({ loading: false }));
        }
      };
    }
    return sagaWorker;
  };
  const constructSagaWatcher = (sagaActionType, sagaWorker) => {
    const sagaWatcher = function* () {
      yield takeEvery(sagaActionType, sagaWorker);
    };
    return sagaWatcher;
  };
  const sagaActionTypes = Object.keys(sagaActionContextObject);
  const sagaWatcherObject = {};
  sagaActionTypes.forEach((sagaActionType) => {
    const {
      drawDecrementActionType,
      drawIncrementActionType,
      gotActionType,
      loadingActionType,
      serviceKey,
    } = sagaActionContextObject[sagaActionType];
    const drawDecrementActionCreator = drawDecrementActionCreatorObject[camelCase(drawDecrementActionType)];
    const drawIncrementActionCreator = drawIncrementActionCreatorObject[camelCase(drawIncrementActionType)];
    const gotActionCreator = gotActionCreatorObject[camelCase(gotActionType)];
    const loadingActionCreator = loadingActionCreatorObject[camelCase(loadingActionType)];
    const sagaWatcherKey = getSagaWatcherKey(sagaActionType);
    const byParameter = getByParameter(sagaActionType);
    const sagaWorker = constructSagaWorker({
      drawDecrementActionCreator,
      drawIncrementActionCreator,
      gotActionCreator,
      loadingActionCreator,
    }, byParameter, serviceKey);
    sagaWatcherObject[sagaWatcherKey] = constructSagaWatcher(sagaActionType, sagaWorker);
  });
  return { sagaWatcherObject };
};

/**
 * 1. Create actions
 */
const generatedActions = generateActions(flattenedChains);

/**
 * 2. Create reducers and selectors
 */
const generatedHandlersAndSelectors = generateHandlersAndSelectors(generatedActions);

/**
 * 3. Create saga workers
 */
const generatedSagaWatchers = generateSagaWatchers(generatedActions);
const {
  broadcastSocketEventActionCreatorObject,
  drawDecrementActionCreatorObject,
  drawIncrementActionCreatorObject,
  gotActionCreatorObject,
  loadingActionCreatorObject,
  sagaActionCreatorObject,
} = generatedActions;
const {
  handlerObject,
  initialStateObject,
  selectorObject,
} = generatedHandlersAndSelectors;
const { sagaWatcherObject } = generatedSagaWatchers;
const actionCreators = {
  ...broadcastSocketEventActionCreatorObject,
  ...drawDecrementActionCreatorObject,
  ...drawIncrementActionCreatorObject,
  ...gotActionCreatorObject,
  ...loadingActionCreatorObject,
  ...sagaActionCreatorObject,
  ...overriddenActionCreators,
};
const handlers = {
  ...handlerObject,
  ...overriddenHandlers,
};
const selectors = {
  ...selectorObject,
  ...overriddenSelectors,
};
const state = merge(
  {},
  { ...initialStateObject },
  { ...overriddenState },
);
const sagaWatchers = {
  ...sagaWatcherObject,
  ...overriddenSagaWatchers,
};
const reducers = handleActions(handlers, state);
const saga = function* () {
  return yield all(Object.values(sagaWatchers).map(watcher => fork(watcher)));
};
export {
  actionCreators,
  reducers,
  saga,
  selectors,
  state,
};
