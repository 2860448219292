/**
 * Helpers
 */
import { createActionCreator } from '../../helpers';

const actionTypes = {
  DECREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW: 'DECREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW',
  GET_CURRENT_USER_PERMISSIONS: 'GET_CURRENT_USER_PERMISSIONS',
  GETTING_CURRENT_USER_PERMISSIONS: 'GETTING_CURRENT_USER_PERMISSIONS',
  GOT_CURRENT_USER_PERMISSIONS: 'GOT_CURRENT_USER_PERMISSIONS',
  INCREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW: 'INCREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW',
};
const actionCreators = {
  decrementGetCurrentUserPermissionsDraw: createActionCreator(actionTypes.DECREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW),
  getCurrentUserPermissions: createActionCreator(actionTypes.GET_CURRENT_USER_PERMISSIONS),
  gettingCurrentUserPermissions: createActionCreator(actionTypes.GETTING_CURRENT_USER_PERMISSIONS),
  gotCurrentUserPermissions: createActionCreator(actionTypes.GOT_CURRENT_USER_PERMISSIONS),
  incrementGetCurrentUserPermissionsDraw: createActionCreator(actionTypes.INCREMENT_GET_CURRENT_USER_PERMISSIONS_DRAW),
};
export {
  actionCreators,
  actionTypes,
};
